<!--
 * @Author: your name
 * @Date: 2020-11-26 11:07:46
 * @LastEditTime: 2021-03-25 14:51:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterDetail.vue
-->
<template>
    <div>
        <el-dialog
        :title="$t('project.Equipment-detail')"
        :visible="visibleInfo"
        width="40%"
        :before-close="handleClose">
        
        <div class="inverterDetail-main-box">
            <el-divider content-position="center">AC Nominal Input</el-divider>
            <el-row   style="margin-bottom:10px">
                <el-col :offset="2" :span="10">Voltage(V) : {{evChargerDetailData.inputVoltage}}</el-col>
                <el-col :offset="2" :span="10">Frequency(Hz) : {{evChargerDetailData.inputFrequency}}</el-col>
            </el-row>
            <el-row   style="margin-bottom:10px">
                <el-col :offset="2" :span="10">
                    {{$t('evcharger.data.Phases/Lines')}} : {{evChargerDetailData.pilePhase == 0?$t('single.phase'):evChargerDetailData.pilePhase == 1?$t('three.phase'):''}}
                </el-col>
            </el-row>
            <el-divider content-position="center">AC Nominal Output</el-divider>
            <el-row   style="margin-bottom:10px">
                <el-col :offset="2" :span="10">
                    Voltage(V) : {{evChargerDetailData.outputVoltage}}
                </el-col>
                <el-col :offset="2" :span="10">
                    Current(A) : {{evChargerDetailData.outputCurrent}}
                </el-col>
            </el-row>
            <el-row   style="margin-bottom:10px">
                <el-col :offset="2" :span="10">
                    Power(kW) : {{evChargerDetailData.outputPower}}
                </el-col>
            </el-row>
            <el-row   style="margin-bottom:10px">
                <el-col :offset="2" :span="20" style="display:flex">
                    Hardware: 
                    <el-checkbox-group v-model="evChargerDetailData.hardware" style="margin-left:10px">
                        <el-checkbox :disabled="true" label="1">Plug</el-checkbox>
                        <el-checkbox :disabled="true" label="2">Socket</el-checkbox>
                        <el-checkbox :disabled="true" label="3">LCD</el-checkbox>
                    </el-checkbox-group>
                </el-col>
            </el-row>
            <el-row   style="margin-bottom:10px">
                <el-col :offset="2" :span="20" style="display:flex">
                    Application: 
                    <el-radio :disabled="true" style="margin-left:10px" v-model="evChargerDetailData.application" :label="0">Home</el-radio>
                    <el-radio :disabled="true" v-model="evChargerDetailData.application" :label="1">Commercial</el-radio>
                </el-col>
            </el-row>
        </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        evChargerDetailData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {

        };
    },
    computed: {
        visibleInfo(){
            return this.$store.state.sysStore.visibleEvChargerInfo
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleClose(){
            this.$store.commit('setVisibleEvChargerInfo',false)
        },
    },
};
</script>

<style  lang="less" >
.inverterDetail-main-box{
    .modal-box-flex{
        display: flex;
        // justify-content: space-between;
        padding: 5px;
            div:nth-child(1){
            flex: 2
            }
            div:nth-child(2){
            flex: 1
            }
    }
}
</style>
