<!--
 * @Author: your name
 * @Date: 2020-11-26 11:07:46
 * @LastEditTime: 2021-03-25 14:34:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterDetail.vue
-->
<template>
    <div>
        <el-dialog
        :title="$t('project.inverter-Info')"
        :visible="visibleInfo"
        width="40%"
        :before-close="handleClose">
        
        <div class="inverterDetail-main-box">
            <el-row :gutter="20">
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.regular-data')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.protection-class')}}</div>
                        <div>{{detailData.protection}}</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('new.PV.management.width')}}</div>
                        <div>{{detailData.width}} mm</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.height')}}</div>
                        <div>{{detailData.height}} mm</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.depth')}}</div>
                        <div>{{detailData.depth}} mm</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('new.PV.management.weight')}}</div>
                        <div>{{detailData.weight}} kg</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.high-switching')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.max-efficiency')}}</div>
                        <div>{{detailData.etaInvMax}} %</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.european-efficiency')}}</div>
                        <div>{{detailData.etaInvEuropean}} %</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.input-data')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('new.PV.management.Max.DC-power')}}:</div>
                        <div>{{detailData.pInvDCmax?detailData.pInvDCmax/1000:'-'}} kW</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('new.PV.management.Max.DC-voltage')}}:</div>
                        <div>{{detailData.uInvDCmax}} V</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.DC-rated-voltage')}}</div>
                        <div>{{detailData.uInvDCnom}} V</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.inv.u-inv-PV-xi')}}</div>
                        <div>{{detailData.uInvPVmin}}/{{detailData.uInvPVmax}} V</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <div class="modal-box-flex">
                        <div style="flex: inherit;">{{$t('project.input-current')}}</div>
                        <div>{{returnA()}} A</div>
                    </div>
                </el-col>
                <el-col :span="20" :offset="1">
                    <el-divider content-position="left">{{$t('project.output-data')}}</el-divider>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.Max.AC-power')}}</div>
                        <div>{{detailData.pInvACmax?detailData.pInvACmax/1000:'-'}} kW</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.data.nominal-AC-power')}}</div>
                        <div>{{detailData.pInvACnom?detailData.pInvACnom/1000:'-'}} kW</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('inverter.info.min.power')}}</div>
                        <div>{{detailData.powerFactorMin}}</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.inv.u-inv-AC-from')}}</div>
                        <div>{{detailData.uInvACFrom}}/{{detailData.uInvACTo}} V</div>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="modal-box-flex">
                        <div>{{$t('project.grid-frequency')}}</div>
                        <div>{{detailData.gridFrequFrom}}/{{detailData.gridFrequTo}} Hz</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        detailData:{
            type:Object,
            default:()=>{return {}}
        }
    },
    data() {
        return {

        };
    },
    computed: {
        visibleInfo(){
            return this.$store.state.sysStore.visibleInfo
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleClose(){
            this.$store.commit('setVisibleInfo',false)
        },
        returnA(){
            let data = this.detailData.tpvParamList || []
            let arr = data.map(item => item.iInvDCMax)
            return arr.join('/')
        }
    },
};
</script>

<style  lang="less" scoped>
.inverterDetail-main-box{
    .modal-box-flex{
    display: flex;
    justify-content: space-between;
    padding: 5px;
        div:nth-child(1){
        flex: 2
        }
        div:nth-child(2){
        flex: 1
        }
    }
}
</style>
