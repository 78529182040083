<template>
  <div class="inverterData-main-box">
    <div class="left-sub-box">
      <el-card>
        <!-- 设备检查-->
        <div slot="header" class="clearfix">
          <span>{{$t('equipment.data.check')}}</span>
        </div>
        <!-- 设备检查 -->
        <div class="equipment_check">
          <el-divider content-position="center">{{$t('about.inverters')}}</el-divider>
          <div class="invter_check">
              <div v-for="item in form.forms" :key="item.id" class="invter_check_item">
                <div> <span>{{$t('inverter.model')}}: </span> {{item.invName}}</div>
                <div> <span>{{$t('project.data.number')}}: </span> {{item.inverterNum}}</div>
              </div>
          </div>
          <el-divider content-position="center">{{$t('project.data.PV-module')}}</el-divider>
          <div class="invter_check">
              <div v-for="item in form.pvArrays" :key="item.id" class="invter_check_item">
                <div> <span>{{$t('inverter.model')}}: </span> {{item.name}}</div>
                <div> <span>{{$t('project.data.number')}}: </span> {{item.usedModuleCount}}</div>
              </div>
          </div>
          <el-divider content-position="center" v-if="batteryName&&projectData.batteryRequired==1">{{$t('project.battery')}}</el-divider>
          <div class="invter_check" v-if="batteryName&&projectData.batteryRequired==1">
              <div class="invter_check_item">
                <div> <span>{{$t('inverter.model')}}: </span> {{batteryName}}</div>
                <div> <span>{{$t('project.data.number')}}: </span> {{battery.batteryNum}}</div>
                <div> <span>{{$t('battery_bms')}}: </span> {{battery.masterBox}}</div>
                <div> <span>{{$t('battery_bms_num')}}: </span> {{battery.masterBoxNum}}</div>
              </div>
          </div>
          <!-- 逆变器 -->
          <!-- <el-table
            :data="tableData"
            :border="false"
            style="width: 100%"
            align="center"
            :show-header="false"
            stripe
          >
            <el-table-column prop="date" width="180">
              <template> {{$t('project.data.inverter')}} </template>
            </el-table-column>
            <el-table-column prop="date">
              <el-table
                :data="form.forms"
                style="width: 100%"
              >
                <el-table-column prop="invName" :label="$t('inverter.model')" width="width">
                </el-table-column>
                <el-table-column prop="inverterNum" :label="$t('project.data.number')" width="width">
                </el-table-column>
              </el-table>
            </el-table-column>
          </el-table> -->
          <!-- 光伏模块 -->
          <!-- <el-table
            :data="tableData"
            :border="false"
            style="width: 100%"
            align="center"
            :show-header="false"
          >
            <el-table-column prop="prop" width="180">
              <template> {{$t('project.data.PV-module')}} </template>
            </el-table-column>
            <el-table-column>
              <el-table :data="form.pvArrays" style="width: 100%" :show-header="false">
                <el-table-column prop="name"  width="width">
                </el-table-column>
                <el-table-column
                  prop="usedModuleCount"
                  width="width"
                >
                </el-table-column>
              </el-table>
            </el-table-column>
          </el-table> -->
          <!-- 电池 -->
          <!-- <el-table
            :data="tableData"
            :border="false"
            style="width: 100%"
            align="center"
            :show-header="false"
            v-if="batteryName&&projectData.batteryRequired==1"
          >
            <el-table-column prop="prop" width="180">
              <template >{{$t('project.battery')}}</template>
            </el-table-column>
            <el-table-column>
               {{batteryName}}
            </el-table-column>
            <el-table-column prop="prop" >
                <span style="padding-right:10px">{{battery.batteryNum}}</span>
            </el-table-column>
          </el-table> -->
          <el-divider content-position="center">{{$t('cable')}}</el-divider>
         <!-- 线缆 -->
         <el-row  :gutter="20" class="cableLength">
            <el-col :span="10">{{$t('projetc.data.Length-of-DC-cable')}}</el-col>
            <el-col :span="10"><span >{{poFormData.dcCableLength}}</span></el-col>
         </el-row>
          <el-row :gutter="20" class="cableLength" >
            <el-col :span="10"><span >{{$t('projetc.data.Length-of-AC-cable')}}</span> </el-col>
            <el-col :span="10"><span >{{poFormData.acCableLength}}</span></el-col>
          </el-row>
        </div>
      </el-card>
      <el-form ref="form" :model="form" label-width="150px">
        <el-card style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>{{$t('equipment.data.add')}}</span>
          </div>
          <div>
              <!-- 充电桩 -->
              <el-form-item
                :label="$t('project.evcharger')"
                style="margin-left: 20px"
              >
                <template>
                  <div>
                    <el-row :gutter="10">
                      <el-col :span="6" class="Other_equipment">
                      <el-select v-model="EquipmentCheck.chargerId" clearable  style="width:160px" :placeholder="$t('common.msg.select.please')"
                       @change="OptionalNum(1,'chargerNum',1)"
                       @clear="OptionalNum(1,'chargerNum','')" 
                       >
                          <el-option
                            v-for="item in chargerDataList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                          </el-select>
                          <i class="el-icon-tickets" v-if="EquipmentCheck.chargerId" @click="toEvChargerDetail"
                              style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                          </i>
                      </el-col>
                      <el-col
                        :span="6"
                        style="display: flex; justify-content: center"
                        ><span style="width: 40px; margin: 0 auto"
                          >X</span
                        ></el-col
                      >
                      <el-col :span="6">
                        <el-input
                          controls-position="right"
                          :min="1"
                          style="width: 160px"
                          v-model="EquipmentCheck.chargerNum"
                          @input="value => EquipmentCheck.chargerNum=value.replace(/[^(1-9)]/g,'')"
                          @blur="OptionalNum(2,'chargerNum',1,'chargerId')"
                          :placeholder="$t('common.info.pl-insert-content')">
                        </el-input>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </el-form-item>
              <!-- 热泵 -->
              <el-form-item
                :label="$t('project.heatpump')"
                style="margin-left: 20px"
              >
                <template>
                  <div>
                    <el-row :gutter="10">
                      <el-col :span="6" class="Other_equipment">
                        <el-select v-model="EquipmentCheck.heatpumpId" clearable  style="width:160px" :placeholder="$t('common.msg.select.please')" 
                        @change="OptionalNum(1,'heatpumpNum',1)"
                        @clear="OptionalNum(1,'heatpumpNum','')" 
                        >
                          <el-option
                            v-for="item in heatPumpDataList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                        <i class="el-icon-tickets" v-if="EquipmentCheck.heatpumpId"  @click="toAdapterDetail"
                              style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                          </i>
                      </el-col>
                      <el-col
                        :span="6"
                        style="display: flex; justify-content: center"
                        ><span style="width: 40px; margin: 0 auto"
                          >X</span
                        ></el-col
                      >
                      <el-col :span="6">
                        <el-input
                            controls-position="right"
                            min="1"
                            style="width: 160px"
                            v-model="EquipmentCheck.heatpumpNum"
                            @input="value => EquipmentCheck.heatpumpNum=value.replace(/[^(1-9)]/g,'')"
                            @blur="OptionalNum(2,'heatpumpNum',1,'heatpumpId')"
                            :placeholder="$t('common.info.pl-insert-content')"
                          ></el-input>
                        </el-col>
                    </el-row>
                  </div>
                </template>
              </el-form-item>
          </div>
        </el-card>
        
        <el-card style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>Optional</span>
          </div>
          <div>
            <!-- 4G -->
            <el-form-item
              :label="$t('equipCheck.data.fourG')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.fourGType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'fourGNum',1)"
                        @clear="OptionalNum(1,'fourGNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch['4G_TYPES']"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                    <i class="el-icon-tickets" v-if="EquipmentCheck.fourGType" @click="otherdevicegetDeviceParam(2,EquipmentCheck.fourGType)" style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                    </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        style="width: 160px"
                        v-model="EquipmentCheck.fourGNum"
                        @input="value => EquipmentCheck.fourGNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'fourGNum',1,'fourGType')"
                        :placeholder="$t('common.info.pl-insert-content')"
                      ></el-input
                    ></el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
            <!-- CT -->
            <el-form-item
              :label="$t('equipCheck.data.CT')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.fourGType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'fourGNum',1)"
                        @clear="OptionalNum(1,'fourGNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch['4G_TYPES']"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                    <i class="el-icon-tickets" v-if="EquipmentCheck.fourGType" @click="otherdevicegetDeviceParam(2,EquipmentCheck.fourGType)" style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                    </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        style="width: 160px"
                        v-model="EquipmentCheck.fourGNum"
                        @input="value => EquipmentCheck.fourGNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'fourGNum',1,'fourGType')"
                        :placeholder="$t('common.info.pl-insert-content')"
                      ></el-input
                    ></el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
            <!-- LAN -->
            <el-form-item
              :label="$t('equipCheck.data.LAN')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.lanType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'lanNum',1)"
                        @clear="OptionalNum(1,'lanNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch.LAN_TYPES"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                        <i class="el-icon-tickets" v-if="EquipmentCheck.lanType" @click="otherdevicegetDeviceParam(3,EquipmentCheck.lanType)"
                            style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                        </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        @input="value => EquipmentCheck.lanNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'lanNum',1,'lanType')"
                        style="width: 160px"
                        v-model="EquipmentCheck.lanNum"
                        :placeholder="$t('common.info.pl-insert-content')"
                      ></el-input>
                      </el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
            <!-- WIFI -->
            <el-form-item
              :label="$t('equipCheck.data.WIFI')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.wifiType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'wifiNum',1)"
                        @clear="OptionalNum(1,'wifiNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch.WIFI_TYPES"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                    <i class="el-icon-tickets" v-if="EquipmentCheck.wifiType" @click="otherdevicegetDeviceParam(4,EquipmentCheck.wifiType)"
                        style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                    </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        style="width: 160px"
                        v-model="EquipmentCheck.wifiNum"
                        @input="value => EquipmentCheck.wifiNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'wifiNum',1,'wifiType')"
                        :placeholder="$t('common.info.pl-insert-content')"
                      ></el-input
                    ></el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
            <!-- meter -->
            <el-form-item
              :label="$t('equipCheck.data.METER')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.meterType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'meterNum',1)"
                        @clear="OptionalNum(1,'meterNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch.METER_TYPES"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                        <i class="el-icon-tickets" v-if="EquipmentCheck.meterType" @click="otherdevicegetDeviceParam(5,EquipmentCheck.meterType)"
                            style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                        </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        style="width: 160px"
                        v-model="EquipmentCheck.meterNum"
                        @input="value => EquipmentCheck.meterNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'meterNum',1,'meterType')"
                        :placeholder="$t('common.info.pl-insert-content')"
                      ></el-input
                    ></el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
            <!-- DataHub -->
            <el-form-item
              :label="$t('equipCheck.data.DATAHUB')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.datahubType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'datahubNum',1)"
                        @clear="OptionalNum(1,'datahubNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch.DATAHUB_TYPES"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                        <i class="el-icon-tickets" v-if="EquipmentCheck.datahubType" @click="otherdevicegetDeviceParam(1,EquipmentCheck.datahubType)"
                            style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                        </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        v-model="EquipmentCheck.datahubNum"
                        @input="value => EquipmentCheck.datahubNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'datahubNum',1,'datahubType')"
                        style="width: 160px"
                        :placeholder="$t('common.info.pl-insert-content')"
                      ></el-input
                    ></el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
            <!-- EPS Box -->
            <el-form-item
              :label="$t('equipCheck.data.EPS-BOX')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.epsBoxType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'epsBoxNum',1)"
                        @clear="OptionalNum(1,'epsBoxNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch.EPS_BOX_TYPES"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                    <i class="el-icon-tickets" v-if="EquipmentCheck.epsBoxType" @click="otherdevicegetDeviceParam(6,EquipmentCheck.epsBoxType)"
                        style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                    </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        @input="value => EquipmentCheck.epsBoxNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'epsBoxNum',1,'epsBoxType')"
                        style="width: 160px"
                        v-model="EquipmentCheck.epsBoxNum"
                        :placeholder="$t('common.info.pl-insert-content')"
                      ></el-input
                    ></el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
            <!-- mate box -->
            <el-form-item
              :label="$t('equipCheck.data.MATE-BOX')"
              style="margin-left: 20px"
            >
              <template>
                <div>
                  <el-row :gutter="10">
                    <el-col :span="6" class="Other_equipment"
                      ><el-select
                        v-model="EquipmentCheck.mateBoxType"
                        :placeholder="$t('common.msg.select.please')"
                        style="width:160px"
                        clearable
                        @change="OptionalNum(1,'mateBoxNum',1)"
                        @clear="OptionalNum(1,'mateBoxNum','')"
                      >
                        <el-option
                          v-for="item in DictionaryBatch.MATE_BOX_TYPES"
                          :key="item.id"
                          :label="item.code"
                          :value="item.value"
                        >
                        </el-option> </el-select>
                    <i class="el-icon-tickets" v-if="EquipmentCheck.mateBoxType" @click="otherdevicegetDeviceParam(7,EquipmentCheck.mateBoxType)"
                        style="font-size:20px;margin-left:15px;cursor:pointer;color: rgb(44, 154, 197);">
                    </i>
                    </el-col>
                    <el-col
                      :span="6"
                      style="display: flex; justify-content: center"
                      ><span style="width: 40px; margin: 0 auto"
                        >X</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        controls-position="right"
                        :min="1"
                        style="width: 160px"
                        :placeholder="$t('common.info.pl-insert-content')"
                        v-model="EquipmentCheck.mateBoxNum"
                        @input="value => EquipmentCheck.mateBoxNum=value.replace(/[^(1-9)]/g,'')"
                        @blur="OptionalNum(2,'mateBoxNum',1,'mateBoxType')"
                        @change="tprojectFillEquipmentCheck"
                      ></el-input
                    ></el-col>
                  </el-row>
                </div>
              </template>
            </el-form-item>
          </div>
        </el-card>
      </el-form>
    </div>
    <div class="right-sub-box">
      <rightReport
        :po-form-data="poFormData"
        :data-visible="dataVisible"
        @clickNext="clickNext"
      ></rightReport>
    </div>
    <detail :detailData="detailData"></detail>
    <adapterDetail :adapterDetailData="adapterDetailData"></adapterDetail>
    <evCharger :evChargerDetailData="evChargerDetailData"></evCharger>
  </div>
</template>

<script>
import rightReport from "./rightReport";
import detail from '@/views/projectList/equipmentDetail/detail'
import adapterDetail from '@/views/oldprojectList/equipmentDetail/adapterDetail'
import evCharger from '@/views/oldprojectList/equipmentDetail/evCharger'
export default {
  components: {
    rightReport,
    detail,
    adapterDetail,
    evCharger
  },
  props: {
    poFormData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    next: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Number,
      default: 1,
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    battery: {
      type: Object,
      default() {
        return {};
      },
    },
    projectData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dataVisible: {
        total: false,
        performance: false,
        overview: true,
      },
      batteryName:'',
      tableData: [{}],
      chargerDataList: [],
      heatPumpDataList: [],
      DictionaryBatch: {},
      EquipmentCheck: {
        bmsBoxNum: "",
        bmsBoxType: "",
        chargerId: "",
        chargerNum: "",
        datahubNum: "",
        datahubType: "",
        epsBoxNum: "",
        epsBoxType: "",
        fourGNum: "",
        fourGType: "",
        heatpumpId: "",
        heatpumpNum: "",
        lanNum: "",
        lanType: "",
        mateBoxNum: "",
        mateBoxType: "",
        meterNum: "",
        meterType: "",

        wifiNum: "",
        wifiType: "",
        
      },
      detailData:[],
      adapterDetailData:{},
      evChargerDetailData:{},
    };
  },
  computed:{
    isJapen(){
        return this.$store.state.sysStore.isJapen
    }
  },
  watch: {
    active: {
      handler(nv, ov) {
        if (ov != 6 && nv == 6) {
          this.$emit("changeNext", true);
          this.EquipmentCheck = this.data;
          this.tbatteryListAll()
          this.tchargepileListAll()
          // this.$set(this,"EquipmentCheck",this.data)
        } else if (ov == 6 && nv != 6) {
          this.tprojectFillEquipmentCheck();
          this.tprojectSave()
        }
      },
    },
  },
  mounted() {
    this.tchargepileListAll();
    this.theatpumpListAll();
    this.tdictionaryGetDictionaryBatch();
    // this.tbatteryListAll()
    // this.otherdevicegetDeviceParam()
  },
  methods: {
    // 每个步骤自动保存
    async tprojectSave(){
      // let status = this.$route.query.status>6?this.$route.query.status:6
       let status = parseInt(localStorage.getItem('statusFlag'))
                if (status < 6) {
                    status = 6
                    localStorage.setItem("statusFlag",6)
                }
        let res = await this.API.tprojectSave({
            proId:this.$route.query.id,
            status
        })
    },
    // 点击next
    clickNext() {
      // 日本地区跳过投资回报
      if(this.isJapen){
        this.$emit("nextMenus", this.active + 2);
      }else{
        this.$emit("nextMenus", this.active + 1);
      }
      // this.$emit('changeClick')
      this.$emit('changeNext',false)
    },
    // 获取所有电池接口
    async tbatteryListAll() {
      let res = await this.API.tbatteryListAll({
        projectId: this.$route.query.id,
      });
      res.forEach(item => {
        if (item.id == this.battery.batteryId) {
          this.$nextTick(()=>{
            this.batteryName = item.name
          })
      
        }
      });
    },

    /*
     * 获取所有充电桩数据
     * */
    async tchargepileListAll() {
      let res = await this.API.tchargepileListAll();
      this.chargerDataList = res;
    },

    // 获取所有热泵数据
    async theatpumpListAll() {
      let res = await this.API.theatpumpListAll();
      this.heatPumpDataList = res;
    },

    async tdictionaryGetDictionaryBatch() {
      let res = await this.API.tdictionaryGetDictionaryBatch({
        lang: localStorage.getItem("lan"),
        dictCodes: [
          "METER_TYPES",
          "DATAHUB_TYPES",
          "LAN_TYPES",
          "4G_TYPES",
          "WIFI_TYPES",
          "EPS_BOX_TYPES",
          "BMS_BOX_TYPES",
          "MATE_BOX_TYPES",
          "OTHER_DEVICE_TYPES"
        ],
      });
      this.DictionaryBatch = res;
    },

    // 提交选择设备
    async tprojectFillEquipmentCheck() {
      this.EquipmentCheck.projectId = this.$route.query.id;
      let res = await this.API.tprojectFillEquipmentCheck(this.EquipmentCheck);
      // console.log(res, "res");
      this.$emit("noticeUpload", res);
    },

    // 查看详情
    async otherdevicegetDeviceParam(deviceCode,deviceTypeCode) {
      this.$store.commit("setVisibleDeviceInfo", true);
      let res = await this.API.otherdevicegetDeviceParam({
        deviceCode,
        deviceTypeCode
      });
      this.detailData =[]
      let data = JSON.parse(res.object.paramJson)
      let paramKeys = Object.keys(data)
      console.log(this.detailData,"this.detailData");
      paramKeys.forEach((item)=>{
        let keys = item+'key'
        this.detailData.push({paramKey:item,paramVal:data[item]})
      })
      console.log(this.detailData);
    },

    // 热泵详情
   async toAdapterDetail(){
      this.$store.commit("setVisibleAdapterInfo", true);
      let res = await this.API.theatpumpGetById({
          id: this.EquipmentCheck.heatpumpId
      })
      this.adapterDetailData = res
    },

     // 充电桩详情
   async toEvChargerDetail(){
      this.$store.commit("setVisibleEvChargerInfo", true);
      let res = await this.API.tchargepileGetById({
          id: this.EquipmentCheck.chargerId
      })
      this.evChargerDetailData = res
      if (res.hardware) {
          this.evChargerDetailData.hardware = res.hardware.split(',')
      }else{
          this.evChargerDetailData.hardware = []
      }
    },

    // 判断配件数量的填写
    OptionalNum(flag,NumText,Num,devText){
      if (flag == 1) {
        this.EquipmentCheck[NumText] = Num
      }
      if (flag == 2) {
        if (this.EquipmentCheck[devText]) {
          if (!this.EquipmentCheck[NumText]) {
            this.EquipmentCheck[NumText] = Num
          }
        }
      }
      
    }
  },
};
</script>

<style lang="less">
.el-table::before {
  //   background-color: #fff;
}
.el-table td {
  border-bottom: none;
  text-align: center;
}
.el-table th.is-leaf {
  border-bottom: none;
  text-align: center;
}
.cableLength {
  color: #606266;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}
.Other_equipment{
  display: flex;
  align-items: center;
}
.equipment_check{
  color: #606266;
  font-size: 15px;
  .invter_check{
    
    .invter_check_item{
      display: flex;
      width: 100%;
      margin: 10px 0;
      padding: 0 100px;
      box-sizing: border-box;
      div{
        width: 30%;
        min-width: 200px;
      }
    }
    
  }
}
</style>
<style scoped lang="less">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none !important;
}
::v-deep input[type='number'] {
-moz-appearance: textfield !important;
}
</style>