<template>
  <div class="inverterData-main-box">
    <div class="left-sub-box">
      <el-card style="margin-bottom:20px">
        <div slot="header" class="clearfix">
          <span>{{ $t("battery.data-add") }}</span>
        </div>
        <div>
          <div class="img-and-table-box">
            <div class="img-box" style="height:190px">
              <div style="height:150px;margin-top:0px">
                <img
                :src="imgUrlBase"
                alt=""
                width="100%"
                height="150px"
                style="max-width: 150px"
                v-if="imgFlag"
              />
              </div>
              <div
                @click="showDetailModal"
                style="font-size: 14px;color:#fea429"
              >
                More info
              </div>
            </div>
            <!-- 有推荐方案 -->
            <div class="table-box" v-if="battteryRecommendList.sameBatteryRecommends.length">
              <el-form ref="form" :model="form" label-width="200px">
                <el-row :gutter="10">
                  <el-col :span="10" >
                      <el-form-item :label="$t('project.battery')" > 
                        <el-input  style="width:100%;" v-model="clickSelectBattery.batteryName" :disabled="clickSelectBattery.batteryName" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="10" :offset="2">
                      <el-form-item :label="$t('project.Battery-quantity')" >
                        <el-input  style="width:100%;" v-model="clickSelectBattery.batteryNumber" :disabled="clickSelectBattery.batteryNumber" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                      </el-form-item>
                  </el-col>
                </el-row>
                 <el-row :gutter="10" style="margin-top:25px">
                  
                  <el-col :span="10">
                      <el-form-item :label="$t('project.battery-Max.EPS-power') + '(Kw)'" >
                        <el-input  style="width:100%;" v-model="clickSelectBattery.maxPower" :disabled="clickSelectBattery.maxPower" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="10" :offset="2">
                      <el-form-item :label="$t('project.battery-Total-battery-pack-capacity') + '(Ah)'" > 
                        <el-input  style="width:100%;" v-model="clickSelectBattery.usableEnergy" :disabled="clickSelectBattery.usableEnergy" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                      </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <!-- 无推荐方案 -->
            <div  class="table-box" v-else>
              <el-form ref="form" :model="form" label-width="200px">
                <el-row :gutter="10">
                  <el-col :span="12" >
                      <el-form-item :label="$t('project.battery-type')" prop="batteryId" :rules="[{ required: false, message: $t('remind.not-null') },]" >
                      <el-select v-model="data.batteryId" @change="selectBatteryOne" :label="$t('project.battery-type')" >
                        <el-option
                          v-for="batteryItem in battteryList"
                          :key="batteryItem.id"
                          :label="batteryItem.name"
                          :value="batteryItem.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" >
                      <el-form-item v-if="batteryNumList.length" :label="$t('project.Battery-quantity')" prop="batteryNum" :rules="[{ required: false, message: $t('remind.not-null') },]" >
                        <el-select
                          v-model="data.batteryNum"
                          @change="seleceBatteryAfter" >
                          <el-option
                            v-for="batteryItem in batteryNumList"
                            :key="batteryItem"
                            :label="batteryItem"
                            :value="batteryItem"
                          ></el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item v-else :label="$t('project.Battery-quantity')" >
                        <el-input   v-model="data.batteryNum" :placeholder="$t('common.info.pl-insert-content')" @blur="seleceBatteryAfter" @input="value => data.batteryNum=value.replace(/[^(0-9)(\.)]/g,'')"></el-input>
                      </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10" v-if="data.masterBox"  style="margin-top:20px">
                  <el-col :span="12">
                      <el-form-item :label="$t('battery_bms')" > 
                        <el-input  v-model="data.masterBox" disabled  :placeholder="$t('common.info.pl-insert-content')"></el-input>
                      </el-form-item>
                  </el-col>
                  <!-- <el-col :span="12">
                      <el-form-item :label="$t('battery_bms')" prop="masterBox" :rules="[{ required: false, message: $t('remind.not-null') },]" >
                      <el-select v-model="masterBoxtype" @change="selectBatteryOne" :label="$t('battery_bms')" >
                        <el-option
                          v-for="BmsItem in BmsTypeList"
                          :key="BmsItem.id"
                          :label="BmsItem.code"
                          :value="BmsItem.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col> -->
                  <!-- <el-col :span="12" >
                      <el-form-item :label="$t('battery_bms_num')" >
                        <el-input   v-model="data.masterBoxNum" disabled :placeholder="$t('common.info.pl-insert-content')"></el-input>
                      </el-form-item>
                  </el-col> -->
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </el-card>
      
      <div class="recommend" v-for="item,ind in battteryRecommendList.sameBatteryRecommends" :key="ind" >
        <!-- <div slot="header" class="clearfix">
        </div> -->
        <div class="recommend_Battery" @click="selectRecomBattery(item,ind)" :class="ind == clickActive?'activeBattery':''">
            <el-tooltip class="item" effect="dark" content="The mounted battery quantity under each inverters must be the same." placement="top-start">
                <div >
                  <div v-if="ind == 0" style="color:#606266;margin-bottom:10px">Overview</div>
                  <div style="color:#606266;margin-bottom:10px">Recommendation {{ind +1}}</div>
                  <div class="recommend_Battery_Example" v-for="count,index in item.inverterCount" :key="index">
                      <div class="recommend_Battery_Example_inverter">
                        <i class="iconfont icon-nibianqi"></i>
                        <div>
                            <!-- X1-Hybrid-G4-5.0 -->
                            {{item.inverterName}}
                        </div>
                      </div>
                      <i class="el-icon-plus recommend_Battery_Example_plus"></i>
                      
                      <div class="recommend_Battery_Example_inverter" v-if="item.masterBox||item.parallelBoxs">
                        <img :src="bmsImg" alt="" width="80px" height="35px">
                        <div v-if="item.masterBox">
                            {{item.masterBox}} X 1
                        </div>
                        <div v-if="item.parallelBox">
                            {{item.parallelBox}} X 1
                        </div>
                      </div>
                      <i class="el-icon-plus recommend_Battery_Example_plus" v-if="item.masterBox ||item.parallelBox"></i>
                      <div class="recommend_Battery_Example_inverter">
                        <i class="iconfont icon-dianchi"></i>
                        <div>
                            
                            <span v-if="item.batteryId == 93">
                                <!-- T58 特殊 -->
                                {{item.TbatteryName1}} X {{item.TbatteryNum1}}
                            </span>
                            <span v-else>
                              {{item.batteryName}} X {{item.batteryNum}}
                            </span>
                        </div>
                      </div>
                      <i class="el-icon-plus recommend_Battery_Example_plus" v-if="item.TbatteryName2"></i>
                      <div class="recommend_Battery_Example_inverter" v-if="item.TbatteryName2">
                        <i class="iconfont icon-dianchi"></i>
                        <div>
                            <!-- T58 特殊 -->
                            {{item.TbatteryName2}} X {{item.TbatteryNum2}}
                        </div>
                      </div>
                  </div>
                </div>
            </el-tooltip>
            
            <div class="recommend_Battery_Data" :class="ind == 0?'marginTopL':''">
                <div class="recommend_Battery_Data_item">
                    <span >{{$t("project.Battery-quantity")}}</span>
                    <span style="margin-top: 20px;">{{item.batteryNum * item.inverterCount}}</span>
                </div>
                <div class="recommend_Battery_Data_item">
                    <span>{{ $t("project.battery-Total-battery-pack-capacity") }}</span>
                    <span style="margin-top: 20px;">{{item.usableEnergy.toFixed(2)}} Ah</span>
                </div>
                <div class="recommend_Battery_Data_item">
                    <span>{{ $t("project.battery-Max.EPS-power") }}
                      <el-tooltip class="item" effect="dark" content="Max.EPS power when svstem off-grid" placement="top" >
                          <i class="el-icon-question" style="color: orange; font-size: 16px" ></i> 
                        </el-tooltip>
                    </span>
                    <span style="margin-top: 20px;">{{item.maxPower.toFixed(2)}} Kw</span>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="right-sub-box">
      <rightReport
        :po-form-data="poFormData"
        :dataVisible="dataVisible"
        @clickNext="clickNext"
      ></rightReport>
    </div>
    <batteryDetail :detailData="detailData"></batteryDetail>
  </div>
</template>

<script>
import rightReport from "./rightReport";
import batteryDetail from "./batteryDetail";
export default {
  components: {
    rightReport,
    batteryDetail,
  },
  props: {
    poFormData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    next: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dataVisible: {
        total: false,
        performance: false,
        overview: true,
      },
      form: {},
      imgUrlBase: require("../../assets/img/T58.png"),
      bmsImg: require("../../assets/img/masterBox.png"),
      imgFlag: false,
      number: 1,
      batteryOverview: [{}],
      tableHead: [{}],
      detailData: {},

      battteryRecommendList: [],
      battteryList: [],
      radioBattery: "",
      customBattery: false,
      selectBatteryArr: {},
      // batteryOverview:{},
      batteryId: "",
      flagVisible: false,
      batteryNumList:[],
      clickSelectBattery:{},
      clickActive:null,
      BmsTypeList:[],
      masterBoxtype:''
    };
  },
  watch: {
    active: {
      handler(nv, ov) {
        if (ov != 4 && nv == 4) {
          console.log("lalala");
          this.tprojectBatteryRecommend();
          this.save()
          // this.$emit("changeNext", true);
          if (this.data.batteryId) {
            this.flagVisible = true;
          };
          this.$emit('changeNext',true)
        }
        if (ov == 4 && nv != 4) {
          this.tprojectSave()
          // this.tprojectSelectBattery(this.data.batteryId, this.data.batteryNum);
        }
      },
      immediate: true,
      deep:true
    },
    customBattery: {
      handler(nv, ov) {
        this.radioBattery = "";
      },
      immediate: true,
    },
    batteryId: {
      handler() {
        if (this.batteryId == "") {
          this.customBattery = true;
          this.flagVisible = false;
          return;
        }
        this.flagVisible = true;
      },
      immediate: true,
    },
    imgUrlBase: {
      handler(nv, ov) {
        this.imgFlag = false;
        let that = this;
        setTimeout(() => {
          that.imgFlag = true;
        }, 1000);
      },
      deep: true,
      immediate: true,
    },
    data:{
      handler(nv){
        this.$nextTick(()=>{
          this.save()
        });
        
      },
      immediate: true,
      deep:true
    },
    battteryRecommendList:{
      handler(nv){
        ( nv.sameBatteryRecommends||[]).forEach((item)=>{
          if (parseInt(item.batteryId) == this.data.batteryId&&item.batteryNum == this.data.batteryNum) {
            this.batteryId = parseInt(item.batteryId)
            this.customBattery = false;
          }
        });
        ( this.battteryRecommendList.sameBatteryRecommends||[]).forEach((item,index)=>{
            // console.log(this.data.batteryId == item.batteryId&&(item.batteryNum * item.inverterCount) == this.data.batteryNum,"超级差");
              if (this.data.batteryId == item.batteryId&&(item.batteryNum * item.inverterCount) == this.data.batteryNum) {
                // this.clickActive = index
                this.selectRecomBattery(item,index)
              }
              if (item.batteryId == 93) {
                if (item.batteryNum < 6) {
                  if (item.batteryNum == 1) {
                    item.TbatteryName1 = 'T58(build-in BMS)'
                    item.TbatteryNum1 = 1
                  }
                  if (item.batteryNum > 1) {
                    item.TbatteryName1 = 'T58(build-in BMS)'
                    item.TbatteryName2 = 'T58(slave)'
                    item.TbatteryNum2 = item.batteryNum - 1 
                    item.TbatteryNum1 = 1
                  }
                }else{
                  item.TbatteryName1 = 'T58(slave)'
                  item.TbatteryNum1 = item.batteryNum
                }
                
              }
          })
      },
      immediate:true,
      deep:true
    }
  },
  mounted() {
    this.tbatteryListAll();
    this.getBmsTypeList()
    // this.selectRecomBattery(0)
    
  },
  updated() {
    // this.flagVisible = true;
    if (this.batteryId != "") {
      this.flagVisible = true;
    }
  },
  methods: {
    // 每个步骤自动保存
    async tprojectSave(){
      // let status = this.$route.query.status>4?this.$route.query.status:4
       let status = parseInt(localStorage.getItem('statusFlag'))
                if (status < 4) {
                    status = 4
                    localStorage.setItem("statusFlag",4)
                }
        let res = await this.API.tprojectSave({
            proId:this.$route.query.id,
            status
        })
    },
    save() {
      this.$emit("changeNext", true);
    },
    // 点击next
    clickNext() {
      this.save()
      console.log(this.next,'this.next');
      if (this.next) {
        this.$emit("nextMenus", this.active + 1);
        // this.$emit("changeClick");
        this.$emit('changeNext',false)
      }
      
    },

    // 获取电池详情数据
    async showDetailModal() {
      if (!this.data.batteryId) {
        this.$message.error(this.$t('common.msg.select.please-battery'));
        return;
      }
      this.$store.commit("setVisibleBatteryInfo", true);
      let res = await this.API.tbatteryGetById({
        battery: this.data.batteryId,
      });
      this.detailData = res;
    },

    // 电池推荐
    async tprojectBatteryRecommend() {
      let res = await this.API.tprojectBatteryRecommend({
        projectId: this.$route.query.id,
      });
      this.battteryRecommendList = res;
      if (!this.clickSelectBattery.batteryName) {
        this.selectRecomBattery(res.sameBatteryRecommends[0],0)
        this.tprojectSave()
      }
    },
    // 获取所有电池接口
    async tbatteryListAll() {
      let res = await this.API.tbatteryListAll({
        projectId: this.$route.query.id,
      });
      this.battteryList = res||[];
      this.customBattery = (res.sameBatteryRecommends||[])[0]?false:true;
    },
    // 电池主控列表
    async getBmsTypeList() {
      let res = await this.API.getBmsTypeList({});
      this.BmsTypeList = res||[];
      if (this.BmsTypeList.length) {
        this.masterBoxtype = res[0].code
      }
    },
    // 选择电池
    async tprojectSelectBattery(batteryId, batteryNum) {
      let res = await this.API.tprojectSelectBattery({
        projectId: this.$route.query.id,
        batteryId,
        batteryNum,
      });
      this.flagVisible = false;
      this.$nextTick(() => {
        this.$emit("noticeUpload", res);
      });
      this.returnImgUrl(res.batteryOverview.pictureUrl);
      
    },
    seleceBatteryAfter() {
      this.batteryId = this.selectBatteryArr.batteryId;
      this.radioBattery = "";
      this.tprojectSelectBattery(this.data.batteryId, this.data.batteryNum);
      // this.tprojectBatteryRecommend()
      this.save()
    },
    selectRecomBattery(item,index) {
      console.log(item);
      this.customBattery = false;
      this.tprojectSelectBattery(item.batteryId, item.batteryNum * item.inverterCount);
      this.clickSelectBattery = Object.assign(item)
      this.clickSelectBattery.batteryNumber = item.batteryNum * item.inverterCount
      
      this.clickSelectBattery.usableEnergy = parseFloat(parseFloat(item.usableEnergy).toFixed(2))
      this.clickSelectBattery.maxPower = parseFloat(parseFloat(item.maxPower).toFixed(2))
      // console.log(parseFloat(item.usableEnergy).toFixed(2));
      this.clickActive = index
    },
    // 点击自定义电池
    customEvent(nv) {
      if (nv) {
        this.data.batteryId = "";
        this.batteryId = "";
      }
      
      this.data.batteryId = "";
      this.data.batteryNum= '';
      this.save()
    },
    async returnImgUrl(pictureUrl) {
      if (pictureUrl) {
        let res = await this.API.systemTuserGetLogo({
          fileName: pictureUrl,
        });
        if (res) {
          this.imgUrlBase = "data:image/gif;base64," + res;
        } else {
          this.imgUrlBase = require("../../assets/img/T58.png");
        }
      } else {
        this.imgUrlBase = require("../../assets/img/T58.png");
      }
    },

    selectBatteryOne(val) {
      this.getBatteryAvaNum(val)
      this.save()
      this.tprojectSelectBattery(this.data.batteryId, this.data.batteryNum);
      this.battteryList.forEach((item) => {
        if (item.id == val) {
          // this.returnImgUrl(item.pictureUrl);
        }
      });
    },
    async getBatteryAvaNum(val){
      this.batteryNumList = []
      let res = await this.API.getBatteryAvaNum({
        batteryId:val,
      });
       this.batteryNumList = res
    }
  },
};
</script>

<style lang="less">
.battery-recommemd-detail{
  color: #C0C4CC;
  padding: 8px 15px 8px 15px;
  margin:  10px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  cursor: default;
}
.recommend{
  // border: 1px solid #bbbbbb;
}
.recommend_Battery{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border: 1px solid #bbbbbb;
    .recommend_Battery_Example{
      display: flex;
      margin-bottom: 20px;
      color: #606266;
      font-size: 14px;
      .recommend_Battery_Example_inverter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        i{
          color: #ffaf40;
          font-size: 28px;
        }
        div{
          // color: #ffaf40;
          font-size: 14px;
          margin-top: 10px;
        }
      }
      .recommend_Battery_Example_plus{
        margin: 10px 15px;
      }

    }
    .recommend_Battery_Data{
      // width: 40%;
      display: flex;
      justify-content: space-around;
      color: #606266;
      font-size: 14px;
      .recommend_Battery_Data_item{
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space:nowrap;
        margin-right:30px;
        
      }
    }
    .marginTopL{
      margin-top: 28px;
    }
    
}
.table-box{
  // margin-left: 40px;
  display: flex;
  align-items: center;
}
.activeBattery{
  border: 2px solid #fea429;
}
</style>
