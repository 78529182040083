<template>
  <div class="box">
     <div class="position">N</div>
     <div class="position1">90°</div>
     <div class="position2">0°</div>
      <div class="coordinateAxis" ref="outerring" @mousedown="mousedown" >
        <div class="XAxis"></div>
        <div class="YAxis">
          <i class="el-icon-arrow-left iconFont"></i>
        </div>
        <div class="azimuth"  ref="azimuth" :style = "{transform:'rotateZ('+(num+90)+'deg)'}">
          <span ></span>
        </div>
    </div>
  </div>
  
</template>

<script>
export default {
  props: {
    num: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      flag:false
    }
  },
  watch:{
     num:{
        handler(nv,ov){
          localStorage.setItem("angle",nv)
        },
        deep:true,
        immediate:true
      }
  },
  methods:{
    mousedown(e) {
      console.log('mousedown');
      this.flag = true
      let than = this
      e.preventDefault();
      
      document.onmousemove = function (e){
        console.log('mousemove');
        if (than.flag) {
          let outerrinLeft = than.$refs.outerring.getBoundingClientRect()
          const centerX = outerrinLeft.left + outerrinLeft.width / 2;
          const centerY = outerrinLeft.top + outerrinLeft.height / 2;
          const x = e.clientX ;
          const y = e.clientY ;
          let rotation = Math.atan2(y - centerY, x - centerX) * (180 / Math.PI);
          // if (rotation < 0) {
          //   console.log(rotation,"1");
          //   rotation = 360 + rotation
          //   console.log(rotation,"2");
          // }
          console.log(rotation,"rotation");
          let rotation2 = rotation
          if (rotation<90&&rotation>0) {
            rotation2 =  270 +  rotation
          }else if (rotation>90) {
            rotation2 = rotation - 90
          }else if (rotation>-180&&rotation<=-90){
            rotation2 = rotation + 270
          }else if(rotation>-90&&rotation<=0){
            rotation2 = rotation + 270
          }
          console.log(rotation2);
          localStorage.setItem("angle",rotation2)
          let azimuth = than.$refs.azimuth;
          azimuth.style.transform = `rotateZ(${rotation}deg)`;
        }
      }
      document.onmouseup = function (e) {
        than.$emit('angle')
        than.flag = false
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
  }
}
</script>

<style lang="less" scoped>
@tipFontSize: 16px;
.box{
  width: 100px;
  height: 100px;
  position: absolute;
  top: -16px;
  left: 216px;
  // background: pink;
}
.position{
    position: absolute;
    top: 0;
    left: 54%;
    color:#fea429
  }
  .position1{
    position: absolute;
    top: 50%;
    left: -16px;
    color:red;
    font-weight: 600;
    font-size: @tipFontSize;
    transform: translateY(-50%);
  }
  .position2{
    position: absolute;
    bottom: -16px;
    left: 50%;
    color:red;
    font-weight: 600;
    transform: translateX(-50%);
    font-size: @tipFontSize;
  }
.coordinateAxis{
  // margin: 10px 0;
  // position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  .XAxis{
    width: 80px;
    height:2px;
    background-color: #bbbbbb;
    transform-origin: 0 50%;
  }
  .YAxis{
    width: 80px;
    height:2px;
    background-color: #bbbbbb;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
    // margin: 10px 0;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    position: relative;
    .iconFont{
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      top: -10px;
      left: -10px;
    }
  }
  .azimuth{
    position: absolute;
    top: 0px;
    left: 40px;
    background-color: #fea429;
    width: 50px;
    height:2px;
    transform-style: preserve-3d;
    transform-origin: 0 50%;
    cursor: grabbing;
    // transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
    span{
        display: inline-block;
        width: 70px;
        height: 20px;
        position: absolute;
        top: -10px;
        left: 0px;
    }
  }
}

</style>