<!--
 * @Author: your name
 * @Date: 2020-11-26 09:52:24
 * @LastEditTime: 2021-03-25 14:54:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterData.vue
-->
<template>
    <div class="container-cable">
        <div class="left-sub-box">
            <div class="img-box" style="text-align:center">
                <img src="../../assets/img/grid.jpg" alt="" width="800px">
                <p>{{$t('project.line.loss.tips')}}</p>
            </div>
            <el-card class="box-card margintop_10">
                <div slot="header" class="clearfix">
                     <el-checkbox v-model="considerDcCableLosses" @change='changeDc'>{{$t('user.DC.losses')}}</el-checkbox>
                </div>
                <div>
                    <div class="img-and-table-box">
                        <div class="table-box">
                            <el-table
                                :data="oneTableData"
                                max-height="250"
                                :border='false'
                                style="width: 100%"
                                :span-method="objectSpanMethod"
                                align='center'>
                                <el-table-column prop="name" label="" width="100px">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="number" :label="$t('new.PV.management.cable-material')" width="180px">
                                    <template slot-scope="scope">
                                        <!-- <span class="AB">{{scope.row.number}}{{$t('inverterNumber')}}</span> -->
                                        <span class="AB">MPPT {{scope.row.number}}</span>
                                        <el-select v-model="scope.row.cableDc" @change='tprojectSettingACLine(scope.row)' class="small">
                                            <el-option label="Cu" value="Cu"></el-option>
                                            <el-option label="Al" value="Al"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="cableLengthDc" :label="$t('new.PV.management.single-cable-length-per-string')" width="200px">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.cableLengthDc" controls-position="right" :min="1" @change='tprojectSettingACLine(scope.row)' style='width:60px;'></el-input>m
                                    </template>
                                </el-table-column>
                                <el-table-column prop="sectionIdDcc" :label="$t('new.PV.management.cross-section-per-string')" width="200px">
                                    <template slot-scope="scope">
                                            <el-select v-model="scope.row.sectionIdDc" @change='tprojectSettingACLine(scope.row)' class='select-width'>
                                                <el-option v-for='item in acList' :label="item.areaOption" :value="item.sectionId" :key='item.sectionId'></el-option>
                                            </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dcCurrent" :label="$t('new.PV.management.current')">
                                    <template slot-scope="scope">
                                        {{scope.row.dcCurrent}}A
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dcVoltage" :label="$t('new.PV.management.voltage')" >
                                    <template slot-scope="scope">
                                        {{scope.row.dcVoltage}}V
                                    </template>
                                </el-table-column>
                                <el-table-column prop="decVoltage" :label="$t('new.PV.management.voltage-drop')" >
                                    <template slot-scope="scope">
                                        {{scope.row.decVoltage}}V
                                    </template>
                                </el-table-column>
                                <el-table-column prop="relPowerLoss" :label="$t('new.PV.management.rel.power-loss')" >
                                    <template slot-scope="scope">
                                        {{scope.row.relPowerLoss}}%
                                        <i v-if='scope.row.relPowerLoss<1' class="el-icon-check" style="color:green;font-size: 18px;font-weight: 900;"></i>
                                        <i v-else class="el-icon-close" style="color:red;font-size: 18px;font-weight: 900;"></i>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </el-card>
            <el-card class="box-card margintop_10">
                <div slot="header" class="clearfix">
                     <el-checkbox v-model="considerAcCableLosses" @change='changeAc'>{{$t('user.AC.losses')}}</el-checkbox>
                </div>
                <div>
                    <div class="img-and-table-box">
                        
                        <div class="table-box">
                            <el-table
                                :data="twoTableData"
                                max-height="250"
                                :border='false'
                                style="width: 100%"
                                align='center'>
                                <el-table-column prop="inverterName" label="">
                                    <template slot-scope="scope">
                                        {{scope.row.inverterName}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="acableAc1" :label="$t('new.PV.management.cable-material')" >
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.cableAc1" @change='tprojectSettingDCLine(scope.row)'>
                                            <el-option label="Cu" value="Cu"></el-option>
                                            <el-option label="Al" value="Al"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="cableLengthAc1" :label="$t('new.PV.management.single-length')">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.cableLengthAc1" controls-position="right" :min="1" @change='tprojectSettingDCLine(scope.row)'></el-input>m
                                    </template>
                                </el-table-column>
                                <el-table-column prop="sectionIdAc1" :label="$t('new.PV.management.cross-section')">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.sectionIdAc1" @change='tprojectSettingDCLine(scope.row)'>
                                            <el-option v-for='item in acList' :label="item.areaOption" :value="item.sectionId" :key='item.sectionId'></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="acCurrent" :label="$t('new.PV.management.current')">
                                    <template slot-scope="scope">
                                        {{scope.row.acCurrent}}A
                                    </template>
                                </el-table-column>
                                <el-table-column prop="acvoltage" :label="$t('new.PV.management.voltage')" >
                                    <template slot-scope="scope">
                                        {{scope.row.acvoltage}} V
                                    </template>
                                </el-table-column>
                                <el-table-column prop="decVoltage" :label="$t('new.PV.management.voltage-drop')" >
                                    <template slot-scope="scope">
                                        {{scope.row.decVoltage}}V
                                    </template>
                                </el-table-column>
                                <el-table-column prop="relPowerLosses" :label="$t('new.PV.management.rel.power-loss')" >
                                    <template slot-scope="scope">
                                        {{scope.row.relPowerLosses}}%
                                        <i v-if='scope.row.relPowerLosses<1' class="el-icon-check" style="color:green;font-size: 18px;font-weight: 900;"></i>
                                        <i v-else class="el-icon-close" style="color:red;font-size: 18px;font-weight: 900;"></i>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
        <div class="right-sub-box">
            <rightReport :po-form-data="poFormData" :data-visible="dataVisible" @clickNext="clickNext"></rightReport>
        </div>
    </div>
</template>

<script>
import rightReport from './rightReport'
import baseTable from '../../components/common/baseTable'
export default {
    components: {
        rightReport,
        baseTable
    },
    props: {
        form:{
            type: Array,
            default () {
                return []
            }
        },
        considerAcCableLosses:{
            type:Boolean,
            default:true
        },
        considerDcCableLosses:{
            type:Boolean,
            default:true
        },
        active: {
            type: Number,
            default: 1,
        },
        next:{
            type:Boolean,
            default:false
        },
    },
    data() {
        return {
            poFormData:{},
            dataVisible:{},
            firmName:[],
            oneTableData:[],
            twoTableData:[],
            acList:[]
        };
    },
    computed: {

    },
    watch: {
        form:{
            handler(nv,ov){
                if(nv){
                    this.setOneTableData(nv)
                    this.setTwoTableData(nv)
                }
            },
            deep:true
        },
        active:{
            handler(nv,ov){
                if(ov != 5 &&nv == 5){
                    this.$emit('changeNext',true)
                }else if (ov == 5 &&nv != 5) {
                    this.tprojectSave()
                }
            }
        },
    },
    created() {

    },
    mounted() {
        this.tcrosssectionList()
    },
    methods: {
        // 每个步骤自动保存
        async tprojectSave(){
            // let status = this.$route.query.status>5?this.$route.query.status:5
             let status = parseInt(localStorage.getItem('statusFlag'))
                if (status < 5) {
                    status = 5
                    localStorage.setItem("statusFlag",5)
                }
            let res = await this.API.tprojectSave({
                proId:this.$route.query.id,
                status
            })
        },
        async changeDc(){
            let res = await this.API.tprojectSettingACLine({
                considerLineLosses:this.considerDcCableLosses,
                dc:true,
                projectId:this.$route.query.id,
            })
            this.$store.commit('setPoFormData',res.po)
            this.$emit('noticeUpload',res)
        },
        async changeAc(){
            let res = await this.API.tprojectSettingACLine({
                considerLineLosses:this.considerAcCableLosses,
                dc:false,
                projectId:this.$route.query.id,
            })
            this.$store.commit('setPoFormData',res.po)
            this.$emit('noticeUpload',res)
        },
        async tcrosssectionList(){
            let res = await this.API.tcrosssectionList()
            this.acList = res
        },
        async tprojectSettingACLine(row){
            let res = await this.API.tprojectSettingACLine({
                cable:row.cableDc,
                cableLength:row.cableLengthDc,
                invInputId:row.number-1,
                invSequence:row.invSequence,
                projectId:this.$route.query.id,
                sectionId:row.sectionIdDc,
                dc:true
            })
            // this.poFormData = res.po
            this.$store.commit('setPoFormData',res.po)
            this.$emit('noticeUpload',res)
        },
        async tprojectSettingDCLine(row){
            // console.log(row,'row');
            let res = await this.API.tprojectSettingACLine({
                cable:row.cableAc1,
                cableLength:row.cableLengthAc1,
                invSequence:row.invSequence,
                projectId:this.$route.query.id,
                sectionId:row.sectionIdAc1,
                dc:false
            })
            this.$store.commit('setPoFormData',res.po)
            this.$emit('noticeUpload',res)
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                // 判断是不是第0个，如果是，直接合并lengthNumber行
                if(rowIndex == 0){
                    return {
                        rowspan: row.lengthNumber,
                        colspan: 1
                    };
                }
                // 判断与前一个是不是同一个数据源,如果是就不显示这列的这一行
                else if(row.solaxKey == this.oneTableData[rowIndex -1].solaxKey){
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
                // 如果不一样，直接合并lengthNumber行
                else{
                    return {
                        rowspan: row.lengthNumber,
                        colspan: 1
                    };
                }
            }
        },
        setTwoTableData(data){
            this.twoTableData = data
        },
        setOneTableData(data){
            let arr = []
            let j = 1
            data.forEach(item =>{
                item['invSequence'] = j
                let i=0
                let lengthNumber = item.dcCableLosses.length
                let solaxKey = j
                item.dcCableLosses.forEach(itemSub =>{
                    i++
                    itemSub['invSequence'] = item['invSequence']
                    itemSub['number'] = i
                    itemSub['name'] = item.inverterName
                    itemSub['lengthNumber'] = lengthNumber
                    itemSub['solaxKey'] = solaxKey
                    arr.push(itemSub)
                })
                j++
            })
            this.oneTableData = arr
        },
        tableClick(row, column, event){
            console.log(row,'row');
        },

        // 点击next
        clickNext(){
            this.$emit('nextMenus',this.active+1)
            this.$emit('changeNext',false)
        }
    },
};
</script>

<style  lang="less" >

.container-cable{
    .select-width{
        width: 100px !important;
        .el-input{
            width: 100px !important;
        }
    }
    display: flex;
    .el-card__header{
        background-color: #ddd;
        cursor:pointer;
    }
    
    .left-sub-box{
        flex: 9;
        padding: 10px;
        .box-card{
            .el-card__header:hover{
                color: rgb(44, 154, 197);
            }
        }
    }
    .right-sub-box{
        flex: 3;
        padding: 10px;
    }
    .img-and-table-box{
        display: flex;
        .img-box{
            flex: 1;
        }
        .table-box{
            flex: 7;
        }
    }
    .el-table::before{
        background-color: #fff;
    }
    .el-table td{
        border-bottom: none;
        text-align: center;
    }
    .el-table th.is-leaf{
        border-bottom: none;
        text-align: center;
    }
    .margin-top20{
        margin-top:20px;
    }
    .green-color{
        color: green;
    }
    .red-color{
        color: red;
    }
    

    .el-table__row{
        .el-input,
        .el-select{
            display: inline-block;
            width: 70px;
            margin: 0 5px;
            &.small{
                width: 50px;
            }
            .el-form-item--small .el-form-item__error {
                left: 208px;
            }
        }
        .input-title{
            height: 80px;
            line-height: 80px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .AB{
        line-height: 30px;
    }
    .el-card__header {
        padding: 8px 10px;
    }
    
}
</style>
