<!--
 * @Author: your name
 * @Date: 2020-11-26 09:52:24
 * @LastEditTime: 2021-03-25 16:40:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterData.vue
-->
<template>
    <div class="container-result">
        <div class="left-sub-box">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                     {{$t('new.PV.management.result')}}
                </div>
                <div>
                    <div class="img-and-table-box">
                        
                        <div class="table-box">
                            <el-table
                                :data="tableData"
                                :row-class-name="tableRowClassName"
                                max-height="250"
                                :border='false'
                                style="width: 100%"
                                align='center'>
                                <el-table-column prop="invName" label="">
                                    <template slot-scope="scope">
                                        <i v-if='scope.row.statusImg == -1' class="el-icon-warning" style="color:gray;font-size:16px;" ></i>
                                        <i v-if='scope.row.statusImg == 0' class="el-icon-success" style="color:green;font-size:16px;" ></i>
                                        <i v-if='scope.row.statusImg == 1' class="el-icon-warning" style="color:orange;font-size:16px;" ></i>
                                        <i v-if='scope.row.statusImg == 2' class="el-icon-warning" style="color:red;font-size:16px;" ></i>
                                        {{scope.row.invName}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="peakPower" :label="$t('new.PV-peak-power')">
                                    <template slot-scope="scope">
                                        {{scope.row.peakPower}}kW
                                    </template>
                                </el-table-column>
                                <el-table-column prop="moduleCount" :label="$t('project.data.total-PV-modules')">
                                    <template slot-scope="scope">
                                        {{scope.row.moduleCount}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="inverterNum" :label="$t('project.data.number-of-inverters')">
                                    <template slot-scope="scope">
                                        {{scope.row.inverterNum}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="pinvAcnom" :label="$t('project.data.nominal-AC-power')">
                                    <template slot-scope="scope">
                                        {{scope.row.pinvAcnom}}kW
                                    </template>
                                </el-table-column>
                                <el-table-column prop="ioAnnualEnergy" :label="$t('project.data.annual-energy-yield')" >
                                    <template slot-scope="scope">
                                        {{scope.row.ioAnnualEnergy}}kWh
                                    </template>
                                </el-table-column>
                                <el-table-column prop="ioLineLoss" :label="$t('project.data.line-losses')" >
                                    <template slot-scope="scope">
                                        {{scope.row.ioLineLoss}}%
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </el-card>
            <el-card class="box-card margintop_10">
                <div slot="header" class="clearfix">
                    {{$t('project.other-info')}}
                </div>
                <div>
                    <ul class="info-wrap">
                        <li v-show="i!=0" v-for="(item,i) in data.invData" :key="item.id">
                            <span class="name">
                                <i v-if='item.statusImg == -1' class="el-icon-warning" style="color:gray;font-size:16px;" ></i>
                                <i v-if='item.statusImg == 0' class="el-icon-success" style="color:green;font-size:16px;" ></i>
                                <i v-if='item.statusImg == 1' class="el-icon-warning" style="color:orange;font-size:16px;" ></i>
                                <i v-if='item.statusImg == 2' class="el-icon-warning" style="color:red;font-size:16px;" ></i>
                                {{item.invName}}
                            </span>	
                            <span class="info" v-html="infoData[item.resultInfo]"></span>
                        </li>
                    </ul>
                </div>
            </el-card>
            <div class="margintop_10 text-center">
                <el-button type="primary" @click='tprojectSave'>{{$t('new.PV.management.save-project')}}</el-button>
                <el-button type="primary" @click='tprojectReoprt'>{{$t('new.PV.management.PDF-file')}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import rightReport from './rightReport'
import baseTable from '../../components/common/baseTable'
export default {
    components: {
        rightReport,
        baseTable
    },
    props: {
        data:{
            type: Object,
            default:{}
        },
        active: {
            type: Number,
            default: 1,
        },
        form: {
                type: Object,
                default:()=>{
                    return {}
                }
            },
    },
    data() {
        return {
            tableData:[],
            // infoData:{
            //     1:'1	max.pv.array.voltage.smaller=在选定的电网电压上，光伏电压低于逆变器的最低直流电压，因而将发生电能损失！',
            //     2:'2	max.pv.array.current.bigger=最大光伏阵列电流大于允许通过的逆变器的最大直流电流。因而将发生电能损失！',
            //     3:'3	max.voltage.bigger.than.pv.array=最大光伏电压高于光伏模块运行的最大系统电压。因而光伏阵列面临危险！	',
            //     4:'4	max.voltage.bigger.than.inverter=最大光伏电压高于逆变器的最大直流电压。因而逆变器将面临危险。',
            //     5:'5	nominal.Power.Ratio.smaller.result=光伏阵列和逆变器类型部分兼容，因为逆变器的尺寸小于这一组合（< {0}%）。',
            //     6:'6	nominal.Power.Ratio.bigger.result=光伏阵列和逆变器类型部分兼容，因为逆变器的尺寸超出了这一组合（> {0}%）。	',
            //     21:'21	max.pv.array.current.bigger=最大光伏阵列电流大于允许通过的逆变器的最大直流电流。因而将发生电能损失！max.pv.array.voltage.smaller在选定的电网电压上，光伏电压低于逆变器的最低直流电压，因而将发生电能损失！',
            //     32:'32	max.voltage.bigger.than.pv.array=最大光伏电压高于光伏模块运行的最大系统电压。因而光伏阵列面临危险！max.pv.array.current.bigger=最大光伏阵列电流大于允许通过的逆变器的最大直流电流。因而将发生电能损失！	',
            //     42:'42	max.voltage.bigger.than.inverter=最大光伏电压高于逆变器的最大直流电压。因而逆变器将面临危险。max.pv.array.current.bigger=最大光伏阵列电流大于允许通过的逆变器的最大直流电流。因而将发生电能损失！'
            // },
            infoData:{
                1: this.$t('max.pv.array.voltage.smaller'),
                2: this.$t('max.pv.array.current.bigger'),
                3: this.$t('max.voltage.bigger.than.pv.array'),
                4: this.$t('max.voltage.bigger.than.inverter'),
                5: this.$t('nominal.Power.Ratio.smaller.result'),
                6: this.$t('nominal.Power.Ratio.bigger.result'),
                21:this.$t('max.pv.array.current.bigger') +'<br>'+ this.$t('max.pv.array.voltage.smaller'),
                32:this.$t('max.voltage.bigger.than.pv.array') +'<br>'+ this.$t('max.pv.array.current.bigger'),
                42:this.$t('max.voltage.bigger.than.inverter') +'<br>'+ this.$t('max.voltage.bigger.than.pv.array')
            }
            // proData:{},
            // invData:[]
        };
    },
    computed: {

    },
    watch: {
        data:{
            handler(nv,ov){
                if(nv){
                    this.getData()
                }
            },
            deep:true
        },
        active: {
            handler(nv, ov) {
                if (ov != 8 && nv == 8) {
                // this.$emit("allMenus", true);
                this.$emit('changeNext',true)
                if (localStorage.getItem('statusFlag')<8) {
                    localStorage.setItem("statusFlag",8)
                }
                }
            },
            immediate: true,
        },
    },
    created() {

    },
    mounted() {
        // console.log(this.data)
        this.getData()
    },
    methods: {
        async tprojectSave(){
            localStorage.setItem("statusFlag",9)
            let pvselectFlag = false
            this.form.forms.forEach((item)=>{
                if (item.inverterInputs.length == 0||item.nominalPowerRatio == '0%') {
                    pvselectFlag = true
                }
            })
            if (pvselectFlag) {
                this.$message.error(this.$t('Inverter-selectAllPv.port'));
                return
            }
            let status = parseInt(localStorage.getItem('statusFlag'))
            let res = await this.API.tprojectSave({
                proId:this.$route.query.id,
                status
            })
            if(res.success){
                this.$message.success(this.$t('operate.result.success'));
            }else{
                this.$message.error(this.$t('operate.result.fail'));
            }
        },
        async tprojectReoprt(){
            let pvselectFlag = false
            this.form.forms.forEach((item)=>{
                if (item.inverterInputs.length == 0||item.nominalPowerRatio == '0%') {
                    pvselectFlag = true
                }
            })
            if (pvselectFlag) {
                this.$message.error(this.$t('Inverter-selectAllPv.port'));
                return
            }
            let id = this.$route.query.id;
            let token = sessionStorage.getItem('token')
            // let url = process.env.VUE_APP_BASE_API_DOWNLOAD
            let url =  `${location.protocol}//${location.host}`
            // let url = "http://58.48.209.154:5066/design/"
            // let url = "http://10.5.0.116:5066/design/"
            let lang = localStorage.getItem('lang');
            window.open(`${url}/design/pdf/download?proId=${id}&token=${token}&lang=${lang}`)
        },
        getData(){
            this.tableData=this.data.invData
            // console.log(this.tableData+'2')
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex === 0) {
                return 'warning-row';
            }
            return '';
        }
    },
};
</script>

<style  lang="less" >
.container-result{
    display: flex;
    .el-card__header{
        background-color: #ddd;
        cursor:pointer;
    }
    
    .left-sub-box{
        flex: 9;
        padding: 10px;
        .box-card{
            .el-card__header:hover{
                color: rgb(44, 154, 197);
            }
        }
    }
    .right-sub-box{
        flex: 3;
        padding: 10px;
    }
    .img-and-table-box{
        display: flex;
        .img-box{
            flex: 1;
        }
        .table-box{
            flex: 7;
        }
    }
    .el-table::before{
        background-color: #fff;
    }
    .el-table td{
        border-bottom: none;
        text-align: center;
    }
    .el-table th.is-leaf{
        border-bottom: none;
        text-align: center;
    }
    .margin-top20{
        margin-top:20px;
    }

    .el-table__row{
        .el-input,
        .el-select{
            display: inline-block;
            width: 80px;
            margin: 0 5px;
            .el-form-item--small .el-form-item__error {
                left: 208px;
            }
        }
        .input-title{
            height: 80px;
            line-height: 80px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .AB{
        line-height: 30px;
    }
    

    .el-table .warning-row {
        background: #ffd79c;
        font-size: 14px;
        font-weight: bold;
    }
    .info-wrap{
        li{
            display: table;
            color: #666;
            line-height: 25px;
            font-size: 14px;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            .name{
                display: table-cell;
                width: 200px;
            }
        }
    }
    .el-table th>.cell {
        padding-left:10px 5px 15px;
        line-height: 18px;
    }
    
}
</style>
