<!--
 * @Author: your name
 * @Date: 2020-11-25 14:15:44
 * @LastEditTime: 2021-04-01 17:56:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\projectData.vue
-->
<template>
    <div class="project-data-main-box">
        <div class="left-sub-box">
            <el-form ref="form" :model="form" label-width="275px" label-position='left'>
                <!--项目数据-->
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{$t('project.data')}}</span>
                    </div>
                    <div class="content-mian-box">
                        <el-row :gutter="22">
                            <el-col :span="10" :offset="1">
                                <el-form-item label-width="200px" :label="$t('project.data.project-name')" prop="proName" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                    <el-input v-model="form.proName" :placeholder="$t('common.info.pl-insert-content')" :validate-event="false" @blur="proNameBlur"></el-input>
                                </el-form-item>
                                <el-form-item label-width="200px" :label="$t('project.data.project-number')" prop="proNumber" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                    <el-input v-model="form.proNumber" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <el-form-item :label="$t('project.data.customer')" prop="clientName" :rules="[{ required: false, message: $t('remind.not-null')}]" label-width="100px">
                                    <el-input
                                    type="textarea"
                                    :rows="2"
                                    :autosize="{ minRows: 4, maxRows: 6}"
                                    :placeholder="$t('common.info.pl-insert-content')"
                                    v-model="form.clientName">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <div style="margin-top:20px;">
                    <el-row :gutter="24">
                        <el-col :span="10" :offset="0">
                            <!--所在地-->
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span>{{$t('project.data.location')}}</span>
                                </div>
                                <div class="content-mian-box">
                                    <el-form-item :label="$t('project.data.region')" prop="regionId" :rules="[{ required: true, message: $t('remind.not-null'),trigger: 'blur'}]">
                                        <el-select v-model="form.regionId" 
                                        filterable
                                        :placeholder="$t('common.msg.select.please')" @change="regionChange(form.regionId)">
                                            <el-option
                                                    v-for="item in regions"
                                                    :key="item.regionID"
                                                    :label="item.regionNameInternational"
                                                    :value="item.regionID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="$t('project.data.country')" prop="countryId" :rules="[{ required: true, message: $t('remind.not-null'),trigger: 'blur'}]">
                                        <el-select v-model="form.countryId" 
                                        filterable
                                        :placeholder="$t('common.msg.select.please')" @change="countryChange($event)">
                                            <el-option
                                                    v-for="item in countrys"
                                                    :key="item.countryID"
                                                    :label="item.countryNameInternational"
                                                    :value="item.countryID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="$t('project.data.city')" prop="cityId" :rules="[{ required: true, message: $t('remind.not-null'),trigger: 'blur'}]">
                                        <el-select v-model="form.cityId" 
                                        filterable
                                        :placeholder="$t('common.msg.select.please')" @change="cityChange">
                                            <el-option
                                            v-for="item in citys"
                                            :key="item.cityID"
                                            :label="item.cityNameInternational"
                                            :value="item.cityID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-card>
                            <!--环境温度-->
                            <el-card class="box-card" style="margin-top:20px;">
                                <div slot="header" class="clearfix">
                                    <span>{{$t('project.data.ambient-temperature')}}</span>
                                </div>
                                <div class="content-mian-box">
                                    <div >
                                        <el-form-item :label="$t('project.Min.-temperature')" prop="ambientLowTemp"  label-width='280px'>
                                            <el-input v-model="form.ambientLowTemp" ></el-input>
                                        </el-form-item>
                                        <el-form-item :label="$t('project.design-temperature')" prop="ambientAverageTemp"  label-width='280px'>
                                            <el-input v-model="form.ambientAverageTemp" ></el-input>
                                        </el-form-item>
                                        <el-form-item :label="$t('project.Max.-temperature')" prop="ambientHighTemp"  label-width='280px'>
                                            <el-input v-model="form.ambientHighTemp" ></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="14" :offset="0">
                            <!--电网连接-->
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span>{{$t('project.data.grid-connection')}}</span>
                                </div>
                                <div class="content-mian-box">
                                    <el-form-item :label="$t('project.data.grid-voltage')" prop="gridId" :rules="[{ required: true, message: $t('remind.not-null'),trigger: 'blur'}]">
                                        <el-select v-model="form.gridId" :placeholder="$t('common.msg.select.please')" @change='chnageGrid'>
                                            <el-option :value="item.gridID" :label="item.displayName" v-for="item in PowerGridsList" :key="item.gridID"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item   prop="checked" :rules="[{ required: false, message: $t('remind.not-null')}]" label-width='200px'>
                                        <template slot="label" >
                                            <el-checkbox v-model="form.demandsPowerFactor">
                                               {{$t('project.data.spe-displacement')}}
                                            </el-checkbox>
                                        </template>
                                        <div class="flex-box" style="margin-left:160px;">
                                            <el-select v-model="form.factorfPre"  style="width:80px;">
                                                <el-option :value="1" label="+"></el-option>
                                                <el-option :value="-1" label="-"></el-option>
                                            </el-select>
                                            <el-input style="width:80px;margin-left:15px;" 
                                                      v-model="form.displacementPowerFactor" @change='changeFactor'
                                                      :step="0.01"
                                                      :min='0.8'
                                                      :max='1.0'
                                                      @input="value => form.displacementPowerFactor=value.replace(/[^(0-9)(\.)]/g,'')"
                                                      ></el-input>
                                        </div>
                                    </el-form-item>
                                     
                                    <el-form-item label=" " prop="phase" :rules="[{ required: true, message: $t('remind.not-null')}]" label-width='0px'>
                                        <template >
                                            <div >
                                                <el-radio v-model="form.phase" :label="0" @change="$refs['form'].validateField('phase')">{{$t('single.phase')}}</el-radio>
                                                <el-radio v-if="!(flag_regionID=='4')" v-model="form.phase" :label="1" @change="$refs['form'].validateField('phase')">{{$t('three.phase')}}</el-radio>
                                            </div>
                                        </template>
                                    </el-form-item>
                                    <p>{{$t('project.data.set-grid-voltage')}}</p>
                                </div>
                            </el-card>
                            <!--Application-->
                             <el-card class="box-card" style="margin-top:20px;">
                                <div slot="header" class="clearfix">
                                    <span>{{$t('Application')}}</span>
                                </div>
                                <div class="Application">
                                    <el-form-item  :label="$t('project.data.Need-battery')+'?'"  prop="batteryRequired" :rules="[{ required: true, message: $t('remind.not-null')}]" label-width='200px' style="white-space:nowrap;">
                                        <div class="Application_item_radio">
                                            <el-radio v-model="form.batteryRequired" :label="0" @change="changeBattery">NO</el-radio>
                                            <el-radio v-model="form.batteryRequired" :label="1" style="margin-right:13px" @change="save">Yes</el-radio>
                                        </div>
                                        <div class="Application_item_value">
                                            <el-form-item style="display: inline-block" v-if="form.batteryRequired ==1" prop="batteryConsumption" :rules="[{ required: true, message: $t('remind.not-null')}]" >
                                                <el-input class="item_input" v-model="form.batteryConsumption" :validate-event="false" @input="value => form.batteryConsumption=value.replace(/[^(0-9)(\.)]/g,'')" @change="form.batteryConsumption=form.batteryConsumption<0?0:form.batteryConsumption"  :min="0" @blur="$refs['form'].validateField('batteryConsumption')" :disabled="form.batteryRequired=='0'" style="width:55%;margin-right:10px" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                                <el-select class="item_select" v-model="form.batteryConsumptionUnit" placeholder="" style="display: inline-block;">
                                                    <el-option
                                                        v-for="item in CompanyList"
                                                        :key="item.id"
                                                        :label="item.value"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                     <el-form-item class="yincang" :label="$t('project.data.Need-EVcharger')+'?'" prop="evchargerRequired" :rules="[{ required: true, message: $t('remind.not-null')}]" label-width='200px' style="white-space:nowrap;">
                                        <div class="Application_item_radio">
                                            <el-radio v-model="form.evchargerRequired" :label="0" @change="changeCharger">NO</el-radio>
                                            <el-radio v-model="form.evchargerRequired" :label="1" style="margin-right:13px" @change="save">Yes</el-radio>
                                        </div>
                                        <div class="Application_item_value">
                                            <el-form-item style="display: inline-block" v-if="form.evchargerRequired ==1" prop="evConsumption" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                                <el-input class="item_input" v-model="form.evConsumption" :validate-event="false" @input="value => form.evConsumption=value.replace(/[^(0-9)(\.)]/g,'')"  @change="form.evConsumption=form.evConsumption<0?0:form.evConsumption" :min="0" @blur="$refs['form'].validateField('evConsumption')" :disabled="form.evchargerRequired=='0'" style="margin-right:10px" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                                <el-select class="item_select" v-model="form.evConsumptionUnit" placeholder="" style="display: inline-block;">
                                                    <el-option
                                                        v-for="item in CompanyList"
                                                        :key="item.id"
                                                        :label="item.value"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                     <el-form-item v-if="!(flag_regionID=='4'||getcountryID=='34')" :label="$t('project.data.Need-Heatpump')+'?'"  prop="heatpumpRequired" :rules="[{ required: true, message: $t('remind.not-null')}]" label-width='200px' style="white-space:nowrap;">
                                        <div class="Application_item_radio">
                                            <el-radio v-model="form.heatpumpRequired" :label="0" @change="changeHeatPump">NO</el-radio>
                                            <el-radio v-model="form.heatpumpRequired" :label="1" style="margin-right:13px" @change="save">Yes</el-radio>
                                        </div>
                                        <div class="Application_item_value">
                                            <el-form-item style="display: inline-block" v-if="form.heatpumpRequired ==1" prop="heatpumpConsumption" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                               <el-input class="item_input" v-model="form.heatpumpConsumption" :validate-event="false" @input="value => form.heatpumpConsumption=value.replace(/[^(0-9)(\.)]/g,'')" @change="form.heatpumpConsumption=form.heatpumpConsumption<0?0:form.heatpumpConsumption" :min="0" @blur="$refs['form'].validateField('heatpumpConsumption')" :disabled="form.heatpumpRequired=='0'" style="margin-right:10px" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                                 <el-select class="item_select" v-model="form.heatpumpConsumptionUnit" placeholder="" style="display: inline-block;">
                                                    <el-option
                                                        v-for="item in CompanyList"
                                                        :key="item.id"
                                                        :label="item.value"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                    <el-form-item :label="$t('project.data.Other-loads-consumption')"  prop="otherConsumption" :rules="[{ required: true, message: $t('remind.not-null')}]" label-width='140px' style="white-space:nowrap;">
                                        <div class="Application_item_radio"></diV>
                                        <div class="Application_item_value">
                                         <el-form-item style="display: inline-block">
                                            <el-input class="item_input" v-model="form.otherConsumption" :validate-event="false" @input="value => form.otherConsumption=value.replace(/[^(0-9)(\.)]/g,'')"  :min="0" @blur="$refs['form'].validateField('otherConsumption')" @change="form.otherConsumption=form.otherConsumption<0?0:form.otherConsumption" style="margin-right:10px" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                                            <el-select class="item_select" v-model="form.otherConsumptionUnit" placeholder="" style="display: inline-block;">
                                                <el-option
                                                    v-for="item in CompanyList"
                                                    :key="item.id"
                                                    :label="item.value"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                            </el-form-item>
                                        </div>
                                   </el-form-item>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
        <div class="right-sub-box">
            <rightReport :getcountryID="getcountryID" :po-form-data="poFormData" :data-visible="dataVisible"  @clickNext="clickNext"></rightReport>
        </div>
    </div>
</template>

<script>
import rightReport from './rightReport'

export default {
    components: {
        rightReport
    },
    props: {

        azimuth:{
            type: Object,
            default () {
                return {}
            }
        },

        form:{
            type: Object,
            default () {
                return {}
            }
        },
        active:{
            type:Number,
            default:1,
        },
        poFormData:{
            type:Object,
            default:()=>{return {}}
        },
        next:{
            type:Boolean,
            default:false
        },
    },
    data() {
        return {
            dataVisible:{
                total: false,
                performance: false,
                overview: true
            },

            tempRadio: "1",
            powerFactorBoolean:false,
            factorfPre:1,
            regions:[],
            countrys:[],
            citys:[],
            regionId:'',
            countryID:'',
            firmName:[],
            CompanyList:[
                {id:1,value:'kWh/day'},
                {id:2,value:'kWh/month'},
                {id:3,value:'kWh/quarter'},
                {id:4,value:'kWh/year'}
            ],
            formValid:false,
            PowerGridsList:[], //电网电压列表
            getcountryID:'',
            flag_regionID:'',
        };
    },
    computed: {

    },
    watch: {
        active:{
            handler(nv,ov){
                if(ov == 1 &&nv != 1){
                    this.tprojectUpdateProject()
                }
                if (nv == 1 && ov != 1) {
                    this.$emit('changeNext',true)
                    this.$nextTick(() => {
                        // DOM 更新了
                        console.log("dom update complete")
                        this.$refs["form"].clearValidate()
                    })
                }
            }
        },
        form:{
           handler(nv,ov){
            // console.log(nv,'这是回显的数据');
            this.getcountryID = nv.countryId + ''
            this.flag_regionID = nv.regionId + ''
           },
           deep:true,
           immediate:true
        },
        'form.evchargerRequired':{
            handler(nv,ov){
               if (nv ==0) {
                   this.form.evConsumption =''
               }
               
            }
        },
        'form.heatpumpRequired':{
            handler(nv,ov){
               if (nv ==0) {
                   this.form.heatpumpConsumption =''
               }
               
            }
        },
        'form.regionId':{
            handler(){
                this.getAllCountry()
            }
        },
        'form.countryId':{
            handler(nv){
                if(this.form.countryId == 34){
                    this.$store.commit('setIsJapen',true)
                }else{
                    this.$store.commit('setIsJapen',false)
                }
                this.getAllcity()
            },
            deep:true,
            immediate:true,
        },
    },
    created() {

    },
    mounted() {

        this.$nextTick(() => {
            // DOM 更新了
            console.log("dom update complete")
            this.$refs["form"].clearValidate()
        })
        this.getAllRegion()
        // 获取电网电压
        this.getAllPowerGrids()
        this.$set(this.form,'batteryConsumptionUnit',1)
        this.$set(this.form,'otherConsumptionUnit',2)
        this.$set(this.form,'evConsumptionUnit',2)
        this.$set(this.form,'heatpumpConsumptionUnit',2)
        // this.flag_regionID = localStorage.getItem('flag_regionID')
        
    },
    methods: {
        // 每个步骤自动保存
        async tprojectSave(){
            // localStorage.setItem('statusFlag',1)
            let status = parseInt(localStorage.getItem('statusFlag'))
                if (status < 1) {
                    status = 1
                    localStorage.setItem("statusFlag",1)
                }
            let res = await this.API.tprojectSave({
                proId:this.$route.query.id,
                status
            })
        },
        changeFactor(){
            if(this.form.displacementPowerFactor > 1){
                this.form.displacementPowerFactor = 1
            }
            if(this.form.displacementPowerFactor < 0.8){
                this.form.displacementPowerFactor = 0.8
            }
        },
        async tprojectUpdateProject(){
            let res = await this.API.tprojectUpdateProject({
                ambientAverageTemp:this.form.ambientAverageTemp,
                ambientHighTemp:this.form.ambientHighTemp,
                ambientLowTemp:this.form.ambientLowTemp,
                cellAverageTemp:this.form.cellAverageTemp,
                cellHighTemp:this.form.cellHighTemp,
                cellLowTemp:this.form.cellLowTemp,
                cityId:this.form.cityId,
                clientName:this.form.clientName,
                comment:this.form.comment,
                countryId:this.form.countryId || '',
                demandsPowerFactor:this.form.demandsPowerFactor,
                displacementPowerFactor:this.form.displacementPowerFactor*this.form.demandsPowerFactor,
                gridId:this.form.gridId,
                // gridV:this.form.gridV,
                proName:this.form.proName,
                proNumber:this.form.proNumber,
                regionId:this.form.regionId,
                tempMode:1,
                batteryRequired:this.form.batteryRequired,
                evchargerRequired:this.form.evchargerRequired,
                evConsumption:this.form.evConsumption,
                evConsumptionUnit:this.form.evConsumptionUnit,
                heatpumpRequired:this.form.heatpumpRequired,
                heatpumpConsumption:this.form.heatpumpConsumption,
                heatpumpConsumptionUnit:this.form.heatpumpConsumptionUnit,
                otherConsumption:this.form.otherConsumption,
                otherConsumptionUnit:this.form.otherConsumptionUnit,
                phase:this.form.phase,
                batteryConsumption:this.form.batteryConsumption,
                batteryConsumptionUnit:this.form.batteryConsumptionUnit,

                factorfPre:this.form.factorfPre,
                projectId:this.$route.query.id,
            })
            this.$emit('noticeUpload',res)
            this.tprojectSave()
        },
        /*区域改变*/
        regionChange(id){
            // console.log(id,'这是id');
            // console.log(this.regions,'这是所有区域数据');
            this.flag_regionID = id + ''
            console.log(this.flag_regionID );
            localStorage.setItem('flag_regionID',id)
            this.$set(this.form , 'cityId' , '')
            this.form.countryId = ''
            this.getAllCountry(this.form.regionId)
            this.countrys = []
            this.citys = []
            this.$emit('closeAll',false)
            // this.save()
            // this.tprojectUpdateProject()
        },
        proNameBlur(){
            if(!this.form.proName){
                this.form.proName = this.$t('new.PV.management.new-project')
            }
        },
        /*国家改变*/
        countryChange(e){
            // console.log(e,'6666666666666666666666');
            this.getcountryID = e + ''
            localStorage.setItem('jp_countryID',this.getcountryID)
            this.$set(this.form , 'cityId' , '')
            this.$set(this.form , 'gridId' , '')
            this.getAllcity()
            if(this.form.countryId == 34){
                this.$store.commit('setIsJapen',true)
            }else{
                this.$store.commit('setIsJapen',false)
            }
            // this.save()
        },
        cityChange(){
            this.azimuthChange();
            this.save()
        },

        /*建议角度改变*/
        azimuthChange(){
            let cityId = this.form.cityId
            let city = this.citys.find(item=>item.cityID == cityId)
            this.azimuth.azimuthProposal = city.azimuthProposal
            this.azimuth.inclinationProposal = city.inclinationProposal
            // 同时获取默认电压
            this.$set(this.form , 'gridId' , city.defaultGridID || 13)
        },


        chnageGrid(){
            this.save()
        },
        /*获取所有区域*/
        async getAllRegion(){
            let res = await this.API.systemRegionsGetAll({})
            this.regions = res;
        },

        /*获取所有国家*/
        async getAllCountry(){
            //  console.log(this.form.regionId,'this.form.regionId');
            let res = await this.API.systemCountryGetAll({
                regionId: this.form.regionId
            })
            this.countrys = res
        },

        /*获取所有城市*/
        async getAllcity(){
            let res = await this.API.systemCityGetAll({
                countryID: this.form.countryId
            })
            this.citys = res
        },
        save(){
            
        },
        // 
        ischarger(val){
            console.log(val);
            console.log(123);
        },

        // 点击next
        clickNext(){
            this.$refs['form'].validate((valid) => {
              if (valid) {
                this.$emit('noticeUpload',true)
                // this.$emit('closeAll',true)
                this.$emit('changeNext',true)
                this.formValid = true
              } else {
                // this.$emit('closeAll',false)
                this.formValid = false
                // this.$emit('changeNext',false)
                return false;
              }
            });
            this.save()
            if (this.formValid) {
                this.$emit('nextMenus',this.active+1)
                this.$emit('changeNext',false)
            }
        },
        changeBattery(){
           this.$refs['form'].validateField('batteryRequired')
           this.form.batteryConsumption = ''
       },
       changeHeatPump(){
            this.$refs['form'].validateField('heatpumpRequired')
           this.form.heatpumpConsumption = ''
       },
       changeCharger(){
         this.$refs['form'].validateField('evchargerRequired')
           this.form.evConsumption = ''
       },
        // 获取电网电压
        async getAllPowerGrids(){
            let res = await this.API.getAllPowerGrids({})
            this.PowerGridsList = res||[]
        },
    },
};
</script>

<style  lang="less" >
.project-data-main-box{
    display: flex;
    .el-card__header{
        background-color: #ddd;
    }
    .left-sub-box{
        flex: 9;
        padding: 10px;

    }
    .right-sub-box{
        flex: 3;
        padding: 10px;
    }
    .flex-box{
        display: flex;
    }
    .el-card .box-card .is-always-shadow{
        // transition: transform .3s ease,box-shadow .3s ease;
    }
    .el-card:hover {
        // transform: scale(1.02);
        // transform: translate3d(3px,3px,0px);
        // box-shadow: 0 1px 12px 0 rgba(3, 81, 155, 0.2);
    }
}
.el-form .el-form-item--small .el-form-item__error{
    // width: 62px;
}
.Application_item_radio{
    width: 35%;
    display: inline-block;
}
.Application_item_value{
    width: 65%;
    display: inline-block;
    .item_input{
        width: 55%;
        min-width: 123px;
    }
    .item_select{
        width: 40%;
    }
}
</style>
<style scoped lang="less">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none !important;
}
::v-deep input[type='number'] {
-moz-appearance: textfield !important;
}
::v-deep .el-form-item__error{
    word-break:keep-all;
   
}
.yincang{
    overflow-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
}
</style>

