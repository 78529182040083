<!--
 * @Author: your name
 * @Date: 2020-11-25 17:20:00
 * @LastEditTime: 2021-01-14 14:39:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\right.vue
-->
<template>
    <div class="right-report-main-box">
        <el-card class="box-card" v-if='projectActive ==5' style="margin-bottom:20px;">
            <div slot="header" class="clearfix">
                <span>{{$t('new.PV.management.the-total-loss')}}</span>
            </div>
            <div class="flex-box-right-modal">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                    <td></td>
                    <td>{{$t('user.DC')}}</td>
                    <td>{{$t('user.AC')}}</td>
                    <td>{{$t('user.total')}}</td>
                </tr>
                <tr>
                    <td width='50px'>{{$t('user.cable.length')}}</td>
                    <td>{{poFormData.dcCableLength}} m</td>
                    <td>{{poFormData.acCableLength}} m</td>
                    <td>{{poFormData.totalCableLength}} m</td>
                </tr>
                <tr>
                    <td>{{$t('user.power.loss')}}</td>
                    <td>{{ Number(poFormData.dcPowerLossNom).toFixed(4)}} W</td>
                    <td>{{ Number(poFormData.acPowerLossNom).toFixed(4)}} W</td>
                    <td>{{ Number(poFormData.totalPowerLoss).toFixed(4)}} W</td>
                </tr>

                <tr>
                    <td>{{$t('user.rel.power')}}</td>
                    <td>{{poFormData.dcRelPowerLossNom}} 
                        <i v-if='poFormData.dcRelPowerLossImageStatus == 0' class="el-icon-success" style="color:green;font-size:16px;" ></i>
                        <i v-else class="el-icon-warning" style="color:orange;font-size:16px;" ></i>
                    </td>
                    <td>{{poFormData.acRelPowerLossNom}}
                        <i v-if='poFormData.acRelPowerLossImageStatus == 0' class="el-icon-success" style="color:green;font-size:16px;" ></i>
                        <i v-else class="el-icon-warning" style="color:orange;font-size:16px;" ></i>
                    </td>
                    <td>{{poFormData.totalPowerLossNom}}
                        <i v-if='poFormData.totalPowerLossImageStatus == 0' class="el-icon-success" style="color:green;font-size:16px;" ></i>
                        <i v-else class="el-icon-warning" style="color:orange;font-size:16px;" ></i>
                    </td>
                </tr>
            </table>
            </div>
        </el-card>
        <el-card class="box-card" style="margin-bottom:20px;" v-if='projectActive ==3'>
            <div slot="header" class="clearfix">
                <span>{{$t('new.PV.management.info-of-inverter')}}</span>
            </div>
            <div>
                <div class="left-and-right-box">
                    <span>{{$t('project.nominal-power-ratio')}}</span>
                    <span>{{invFormData&&invFormData.nominalPowerRatio || ''}}
                    <el-tooltip v-if='parseInt(invFormData.nominalPowerRatio)<invFormData.nomPowerFrom' placement="top">
                        <div slot="content">{{$t('nominal.Power.Ratio.smaller0')}}（< {{invFormData.nomPowerFrom}}%）。<br/>{{$t('nominal.Power.Ratio.smaller1')}}<br/>{{$t('nominal.Power.Ratio.smaller2')}}<br/>
                         {{$t('nominal.Power.Ratio.smaller3')}}</div>
                        <i class="el-icon-warning" style="color:orange;font-size:16px;" ></i>
                    </el-tooltip>
                    <el-tooltip v-else-if='parseInt(invFormData.nominalPowerRatio)>invFormData.nomPowerTo && parseInt(invFormData.nominalPowerRatio) < 150' placement="top">
                        <div slot="content">{{$t('nominal.Power.Ratio.smaller0.big')}}（> {{invFormData.nomPowerTo}}%）。<br/>{{$t('nominal.Power.Ratio.smaller1.big')}}<br/>{{$t('nominal.Power.Ratio.smaller2.big')}}<br/>
                       {{$t('nominal.Power.Ratio.smaller3.big')}}</div>
                        <i class="el-icon-warning" style="color:yellow;font-size:16px;" ></i>
                    </el-tooltip>
                    <el-tooltip v-else-if='parseInt(invFormData.nominalPowerRatio)>invFormData.nomPowerTo' placement="top">
                        <div slot="content">{{$t('nominal.Power.Ratio.smaller0.big150.1')}}
                            <br>
                            {{$t('nominal.Power.Ratio.smaller0.big150.2')}}
                        </div>
                        <i class="el-icon-warning" style="color:red;font-size:16px;" ></i>
                    </el-tooltip>
                    </span>
                </div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.annual-energy-yield')}}</span>
                    <span>{{invFormData.ioAnnualEnergy}}kWh</span>
                </div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.line-losses')}}</span>
                    <span>{{invFormData.ioLineLoss?invFormData.ioLineLoss+'%':'-'}}</span>
                </div>
            </div>
        </el-card>
        <el-card class="box-card"  v-if="projectActive !=8 ">
            <div slot="header" class="clearfix">
                <span>{{$t('project.data.project-overview')}}</span>
            </div>
            <div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.total-PV-modules')}}</span>
                    <span>{{poFormData.totalModuleCount || ' - '}}</span>
                </div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.PV-peak-power')}}</span>
                    <span>{{poFormData.totalPeakPower || ' - '}} kW</span>
                </div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.number-of-inverters')}}</span>
                    <span>{{poFormData.totalInvCount || ' - '}} </span>
                </div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.nominal-AC-power')}}</span>
                    <span>{{poFormData.totalPAcnom || ' - '}} kW</span>
                </div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.annual-energy-yield')}}</span>
                    <span>{{poFormData.annualEnergy || ' - '}} kWh</span>
                </div>
                <div class="left-and-right-box">
                    <span>{{$t('project.data.line-losses')}}</span>
                    <span>{{poFormData.totalLineLosses?poFormData.totalLineLosses+'%': '-'}} </span>
                </div>
                <p>{{$t('project.data.important')}}</p>
            </div>
        </el-card>
        <el-button type="primary" @click="nextMenus" class="nextBtn">{{$t('project.data.next')}}</el-button>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        inverterData:{
            type:Object,
            default:()=>{return {}}
        },
        // poFormData:{},
        dataVisible:{
            type:Object,
            default:() =>{return {}}
        },
        getcountryID:{
            type:String,
            default:() =>{return ''}
        }
    },
    data() {
        return {
            jp_countryID:'',
        };
    },
    computed: {
        projectActive(){
            return this.$store.state.sysStore.projectActive
        },
        poFormData(){
            return this.$store.state.sysStore.poFormData
        },
        invFormData(){
            return this.$store.state.sysStore.invFormData
        }
    },
    watch: {
        getcountryID:{
            handler(nv,ov){
                // console.log(nv);
                this.jp_countryID = nv
            },
            deep:true,
            immediate:true,
        },
    },
    created() {

    },
    mounted() {

    },
    methods: {
        nextMenus(){
            console.log(this.jp_countryID,'9999999999999999999999999');
            this.$emit('clickNext')
        }
    },
};
</script>

<style  lang="less">
.right-report-main-box{
    width: 100%;
    .left-and-right-box{
        display: flex;
        justify-content: space-between;
        padding: 5px;
    }
    .left-and-right-box:nth-child(2n+1){
        background-color: #fff0cc;
    }
    .flex-box-right-modal{
        width: 100%;
        tr{
            td:nth-child(1){
                width: 35%;
            }
        }
        tr:nth-child(2n+1){
            background-color: #fff0cc;
        }
        td{
            padding: 5px;
        }
    }
    .box-card{
        font-size: 14px;
        color:#666;
    }
    .el-card__body {
        padding: 8px;
    }
    .nextBtn{
        width: 80%;
        height: 40px;
        margin-left: 10%;
        margin-top: 100px;
    }
}

</style>

