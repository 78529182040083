<!--
 * @Author: your name
 * @Date: 2020-11-24 16:40:23
 * @LastEditTime: 2021-03-25 15:04:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\addprojectList.vue
-->
<template>
    <div class="addProjectList-main-box">
        <!-- 
            next 为当前栏的下一栏是否开启
            active 为当前栏的id
            menus 为数据
            all 修改的时候用，全部打开
            changeClick 为点击事件的回调钩子
         -->
        <div>
            <steps :next='next' :active.sync="active" :data="moduleFormData" :menus='menus' :all='all' @changeClick='changeClick'></steps>
        </div>
        <!-- el组件分割线 -->
        <el-divider content-position="left"></el-divider>
        <div class="tabs-content-box">
            <!-- 项目数据  -->
            <div v-show='active==1'>
                <projectData @nextMenus = 'nextMenus' :next='next' @changeNext='changeNext'  @closeAll='closeAll' :azimuth="azimuth" :form="projectFormData" :po-form-data="poFormData" :active='active' @noticeUpload='noticeUpload'></projectData>
            </div>
            <!-- 光伏模块  -->
            <div v-show='active==2'>
                <moduleData @nextMenus = 'nextMenus' :next='next' @changeNext='changeNext' :active='active' @closeAll='closeAll' :azimuth="azimuth" :data="moduleFormData" :isEdit="true" :po-form-data="poFormData" :cityId="cityId" @noticeUpload='noticeUpload' @PoFormUpload='PoFormUpload'></moduleData>
            </div>
            <!-- 逆变器数据  -->
            <div v-show='active==3'>
                <inverterData @nextMenus = 'nextMenus' :next='next' @changeNext='changeNext' @batteryVisi="batteryVisi" :projectData="projectFormData"  :form="inverterFormData" :po-form-data="poFormData" @noticeUpload='noticeUpload' :active='active'></inverterData>
            </div>
            <!-- 电池数据  -->
            <div v-show='active==4'>
                <batteryData :po-form-data="poFormData" @changeNext='changeNext' :next='next'  :data="batteryFormData"  @noticeUpload='noticeUpload' @nextMenus = 'nextMenus' :active='active'></batteryData>
            </div>
            <!-- 电缆配置  -->
            <div v-show='active==5'>
                <cableData @nextMenus = 'nextMenus' :next='next' @changeNext='changeNext' :active='active' :form="cableFormData" :considerAcCableLosses='considerAcCableLosses'
                :considerDcCableLosses='considerDcCableLosses'  :po-form-data="poFormData" @noticeUpload='noticeUpload'></cableData>
            </div>
            <!-- 设备检查  -->
            <div v-show='active==6' >
                <equipmentCheckData :form="inverterFormData" @changeNext='changeNext' :next='next' :projectData="projectFormData" :data="equipmentFormData" :battery="batteryFormData" :po-form-data="poFormData"  @noticeUpload='noticeUpload'  @nextMenus = 'nextMenus'   :active='active'></equipmentCheckData>
            </div>
            <!-- 投资回报  -->
            <div v-show='active==7'>
                <returnData @nextMenus = 'nextMenus' :next='next' @changeNext='changeNext' :form="returnFormData" :po-form-data="poFormData" :active='active'></returnData>
            </div>
            <!-- 结果概述 -->
            <div v-show='active==8'>
                <resultData @nextMenus = 'nextMenus' :form="inverterFormData" @changeNext='changeNext' :data="resultFormData" :po-form-data="poFormData" :active='active'></resultData>
            </div>
        </div>
    </div>
</template>

<script>
import steps from './steps'
import projectData from './projectData'
import moduleData from './moduleData'
import inverterData from './inverterData/inverterData'
import cableData from './cableData'
import returnData from './returnData'
import resultData from './resultData'
import batteryData from '@/views/oldprojectList/batteryData'
import equipmentCheckData from '@/views/oldprojectList/equipmentCheckData'

export default {
    components: {
        steps,
        projectData,
        moduleData,
        inverterData,
        cableData,
        resultData,
        returnData,
        batteryData,
        equipmentCheckData
    },
    props: {

    },
    data() {
        return {

            azimuth:{
                azimuthProposal:0,
                inclinationProposal:30
            },

            proId:this.$route.query.id,
            data:{},
            cityId:'',
            /*项目数据*/
            projectFormData:{},
            /*光伏模块*/
            moduleFormData:{forms:[{}]},
            /*逆变器*/
            inverterFormData:{},
            /*电缆配置*/
            cableFormData:[],
            /*投资回报*/
            returnFormData:{},
            /*结果概述*/
            resultFormData:{},
            /*项目概述模块数据*/
            poFormData:{},
            /*电池数据*/
            batteryFormData:{},
            /*设备选择数据*/
            equipmentFormData:{},
            active:1,
            showFlag:false,
            next:false,
            all:false,
            menus:[
                {
                    id:1,
                    icon:'iconfont icon-xiangmu',
                    text:this.$t('project.data'),
                    next:true,
                    show:true,
                },
                {
                    id:2,
                    icon:'iconfont icon-mokuai',
                    text:this.$t('project.data.PV-module'),
                    next:false,
                    show:true,
                },
                {
                    id:3,
                    icon:'iconfont icon-nibianqi',
                    text:this.$t('project.data.inverter'),
                    next:false,
                    show:true,
                },
                {
                    id:4,
                    icon:'iconfont icon-dianchi',
                    text:this.$t('project.battery'),
                    next:false,
                    show:false,
                },
                {
                    id:5,
                    icon:'iconfont icon-dianlan',
                    text:this.$t('project.data.cable-dimensioning'),
                    next:false,
                    show:true,
                },
                {
                    id:6,
                    icon:'iconfont icon-huabanfuben',
                    text:this.$t('equipment.data.check'),
                    next:false,
                    show:true,
                },
                {
                    id:7,
                    icon:'iconfont icon-touzi',
                    text:this.$t('project.data.return-data'),
                    next:false,
                    show:true,
                },
                {
                    id:8,
                    icon:'iconfont icon-zongjie',
                    text:this.$t('project.data.result-overview'),
                    next:false,
                    show:true,
                }],
            considerAcCableLosses:'',
            considerDcCableLosses:'',
        };
    },
    computed: {
        isJapen(){
            return this.$store.state.sysStore.isJapen
        }
    },
    watch: {
        active:{
            handler(nv,ov){
                this.$store.commit('setProjectActive',nv)
            },
        },
        poFormData:{
            handler(nv,ov){
                this.$store.commit('setPoFormData',nv)
            }
        },
        isJapen:{
            handler(nv){
                if(nv){
                    this.menus.forEach(item => {
                        if(item.text == this.$t('project.data.return-data')){
                            item.show = false
                        }
                    })
                }else{
                    this.menus.forEach(item => {
                        if(item.text == this.$t('project.data.return-data')){
                            item.show = true
                        }
                    })
                }
            },
            deep:true,
            immediate:true
        }
    },
    created() {

    },
    mounted() {
        // this.wifiEditEntity()
        if(this.proId){
            this.getProjectDetail()
        }
    },
    methods: {
        //刷新全部
        noticeUpload(res){
            this.newAssembleDetail(res)
        },
        //刷新右侧
        PoFormUpload(res){
            this.assemblePoFormData(res);
            this.assembleInverterData(res)
        },
        /*获取项目详情数据*/
        async getProjectDetail(){
            let res = await this.API.projectDetailGetById({
                projectId: this.proId
            })
            localStorage.setItem('flag_regionID',res.object.projectInfo.regionId)
            localStorage.setItem('jp_countryID',res.object.projectInfo.countryId)
            this.assembleDetail(res)
            let status = localStorage.getItem("statusFlag")
            // let status = this.$route.query.status
            this.menus.forEach((item)=>{
                if (item.id <= status) {
                    item.next = true
                }
            })
            
        },
        closeAll(flag){
            this.all = flag
        },
        /*组装电缆配置数据*/
        assembleCableFormData(res){
            this.cableFormData = res.cableLossInfos
            this.considerAcCableLosses = res.considerAcCableLosses
            this.considerDcCableLosses = res.considerDcCableLosses
        },
        /*投资回报*/
        assembleReturnFormData(res){
            this.returnFormData=res.tReturnInvestment
            this.returnFormData.proId=this.proId
        },
        /*结果概述*/
        assembleResultFormData(res){
            if(res && res.po){
                let invData=[]
                let proObj={}
                proObj.invName=res.projectInfo.proName
                proObj.peakPower=res.po.totalPeakPower
                proObj.moduleCount=res.po.totalModuleCount
                proObj.inverterNum=res.po.totalInvCount
                proObj.pinvAcnom=res.po.totalPAcnom
                proObj.ioAnnualEnergy=res.po.annualEnergy
                proObj.ioLineLoss=res.po.totalLineLosses
                proObj.statusImg=null

                invData.push(proObj)
                for(let key in res.iorMap){
                    invData.push(res.iorMap[key])
                }
                let obj={
                    proData:proObj,
                    invData:invData,
                    proName:res.proName
                }
                this.resultFormData=obj
            }
        },
        /*组装详情数据*/
        assembleDetail(res){
            let data = res.object
            this.azimuth.azimuthProposal = data.azimuthProposal
            this.azimuth.inclinationProposal = data.inclinationProposal
            this.assembleProjectFormData(data)
            this.assembleModuleFormData(data)
            this.assemblePoFormData(data);
            this.assembleInverterData(data)
            this.assembleReturnFormData(data)
            this.assembleResultFormData(data)

            this.assembleCableFormData(data)
            this.assembleBatteryFormData(data)
            this.assembletEquipmentFormData(data)
        },
         newAssembleDetail(res){
            let data = res
            this.assembleProjectFormData(data)
            this.assembleModuleFormData(data)
            this.assemblePoFormData(data);
            this.assembleInverterData(data)
            this.assembleCableFormData(data)
            this.assembleResultFormData(data)
            this.assembleBatteryFormData(data)
            this.assembletEquipmentFormData(data)
        },

        /*组装光伏模块数据*/
        assembleModuleFormData(res){
            if(res && res.projectInfo){
                this.cityId=res.projectInfo.cityId
            }
            let data = []
            if(res.pvArrays){
                res.pvArrays.forEach((item,index)=>{
                    if(item){
                        let pv = {
                            name: item.name||  this.$t("new.PV.management.PV-array")+'-'+(index+1),
                            manufacturerId: item.manufacturerId||'',
                            moduleId: item.moduleId||'',
                            moduleCount: item.moduleCount,
                            inclination: item.inclination ,
                            proposalIncli: item.proposalIncli,
                            azimuth: item.azimuth ,
                            proposalAzimuth: item.proposalAzimuth,
                            usedModuleCount: item.usedModuleCount,
                            peakPower: item.peakPower,//默认单位kWp,需要除1000
                            originPearPower:item.peakPower,
                            selectLable:1,
                            select: 1 ,//默认单位kWp
                            pModMPP:item.pModMPP, // 额定功率
                        }
                        if(pv.proposalIncli){
                            pv.inclination = this.azimuth.inclinationProposal
                        }
                        if(pv.proposalAzimuth){
                            pv.azimuth = this.azimuth.azimuthProposal
                        }
                        this.changeMoudleSelect(pv)
                        data.push(pv)
                    }
                })
            }
            this.moduleFormData.forms = data
        },

        /*计算光伏模块峰值功率单位*/
        changeMoudleSelect(item){
            if(item.peakPower > 1000*1000){
                item.select = 3
                item.peakPower = item.peakPower/1000000
            }else if(item.peakPower > 1000 ){
                item.select = 2
                item.peakPower = item.peakPower/1000
            }else {
                item.select = 1
            }
        },


        /*组装项目模块数据*/
        assembleProjectFormData(obj){
            obj = obj.projectInfo
            if(obj){
                let data = {
                    countryId: obj.countryId ? parseInt(obj.countryId) : '',
                    cityId: obj.cityId ?  parseInt(obj.cityId): '',
                    regionId: parseInt(obj.regionId)== null ? '':parseInt(obj.regionId) ,
                    proName: obj.proName== null ? '':(obj.proName) ,
                    proNumber: obj.proNumber== null ? '':(obj.proNumber) ,
                    clientName: obj.clientName== null ? '':(obj.clientName) ,
                    tempMode: obj.tempMode == null ? '':(obj.tempMode) ,
                    cellLowTemp: obj.cellLowTemp== null ? '':(obj.cellLowTemp) ,
                    cellAverageTemp: obj.cellAverageTemp== null ? '':(obj.cellAverageTemp) ,
                    cellHighTemp: obj.cellHighTemp== null ? '':(obj.cellHighTemp) ,
                    ambientLowTemp: obj.ambientLowTemp== null ? '':(obj.ambientLowTemp) ,
                    ambientAverageTemp: obj.ambientAverageTemp== null ? '':(obj.ambientAverageTemp) ,
                    ambientHighTemp: obj.ambientHighTemp== null ? '':(obj.ambientHighTemp) ,
                    gridId: obj.gridId == null ? '':obj.gridId ,
                    comment: obj.comment,
                    demandsPowerFactor: obj.demandsPowerFactor== null ? '':(obj.demandsPowerFactor) ,
                    displacementPowerFactor: obj.displacementPowerFactor== null ? '':(obj.displacementPowerFactor),
                    batteryRequired:obj.batteryRequired== null ? '':(obj.batteryRequired),
                    evchargerRequired:obj.evchargerRequired== null ? '':(obj.evchargerRequired),
                    evConsumption:obj.evConsumption== null ? '':(obj.evConsumption),
                    evConsumptionUnit:obj.evConsumptionUnit== null ? '':(obj.evConsumptionUnit),
                    heatpumpRequired:obj.heatpumpRequired== null ? '':(obj.heatpumpRequired),
                    heatpumpConsumption:obj.heatpumpConsumption== null ? '':(obj.heatpumpConsumption),
                    heatpumpConsumptionUnit:obj.heatpumpConsumptionUnit== null ? '':(obj.heatpumpConsumptionUnit),
                    otherConsumption:obj.otherConsumption== null ? '':(obj.otherConsumption),
                    otherConsumptionUnit:obj.otherConsumptionUnit== null ? '':(obj.otherConsumptionUnit),
                    phase:obj.phase== null ? '':(obj.phase),
                    batteryConsumption:obj.batteryConsumption== null ? '':(obj.batteryConsumption),
                    batteryConsumptionUnit:obj.batteryConsumptionUnit== null ? '':(obj.batteryConsumptionUnit),
                }
                data.factorfPre =
                    (data.displacementPowerFactor && data.displacementPowerFactor > 0) ? 1:-1
                if(data.displacementPowerFactor){
                    data.displacementPowerFactor = Math.abs(obj.displacementPowerFactor);
                }

                localStorage.setItem('flag_regionID',data.regionId)
                localStorage.setItem('jp_countryID',data.countryId)
                this.projectFormData = data;
                // console.log(this.projectFormData,'这是取值的地方');
            }
        },

        batteryVisi(data){
            console.log(data,'项目查看');
            if (data == 1) {
                // let batteryFlag = this.menus.some((item)=>{
                //     return item.id == 4
                // })
                // if (!batteryFlag) {
                //     this.menus.splice(3,0,{
                //         id:4,
                //         icon:'iconfont icon-dianchi',
                //         text:this.$t('project.battery'),
                //         next:false,
                //     })
                // }
                this.menus.forEach(item => {
                    if(item.text == this.$t('project.battery')){
                        item.show = true
                    }
                })
                let status = localStorage.getItem("statusFlag")
                this.menus.forEach((item)=>{
                    if (item.id <= status) {
                        item.next = true
                    }
                })
            }else if (data == 0) {
                // let batteryFlag = this.menus.filter((item)=>{
                //     return item.id !== 4
                // })
                // this.menus = batteryFlag
                this.menus.forEach(item => {
                    if(item.text == this.$t('project.battery')){
                        item.show = false
                    }
                })
            }
        },

        /*组装项目概述数据*/
        assemblePoFormData(obj){
            if(obj && obj.po){
                let po = obj.po
                let data = {
                    totalModuleCount: po.totalModuleCount,
                    totalPeakPower: po.totalPeakPower,
                    totalInvCount: po.totalInvCount,
                    totalPAcnom: po.totalPAcnom,
                    annualEnergy: po.annualEnergy,
                    dcCableLength: po.dcCableLength,
                    dcPowerLossNom: po.dcPowerLossNom,
                    dcRelPowerLossNom: po.dcRelPowerLossNom,
                    acCableLength: po.acCableLength,
                    acPowerLossNom: po.acPowerLossNom,
                    acRelPowerLossNom: po.acRelPowerLossNom,
                    totalCableLength: po.totalCableLength,
                    totalPowerLossNom: po.totalPowerLossNom,
                    totalPowerLoss: po.totalPowerLoss,
                    lineLosses: po.lineLosses,
                    totalLineLosses: po.totalLineLosses,
                    nomPowerFrom:obj.projectInfo.nomPowerFrom,
                    nomPowerTo:obj.projectInfo.nomPowerTo,
                    dcRelPowerLossImageStatus:obj.po.dcRelPowerLossImageStatus,
                    acRelPowerLossImageStatus:obj.po.acRelPowerLossImageStatus,
                    totalPowerLossImageStatus:obj.po.totalPowerLossImageStatus,
                }
                this.poFormData = data
            }
        },


        /*组装逆变器数据*/
        assembleInverterData(obj){
            console.log(obj,"assembleInverterData");
            if(obj && obj.iorMap){
                let arr = [];
                Object.keys(obj.iorMap).forEach(item=>{
                    obj.iorMap[item]['invSequence'] = item
                    arr.push(obj.iorMap[item])
                })
                // this.inverterFormData.forms = arr
                // arr[0].nominalPowerRatio = '140%'
                this.$set(this.inverterFormData,'forms',arr)

                this.inverterFormData.pvArrays = []
                let i = 0
                obj.pvArrays.forEach(item=>{
                    if(item){
                        item['id'] = i
                        // this.inverterFormData.pvArrays.push(item)
                        this.$set(this.inverterFormData.pvArrays,i,item)
                    }
                    i++
                })
                // this.inverterFormData.forms.forEach((item)=>{
                //     item.inverterId = parseInt(item.inverterId)
                // })
                // this.$emit('setpvArraysInv',this.inverterFormData.pvArrays)
                this.$store.commit('setpvArraysInv',this.inverterFormData.pvArrays)
            }
        },

        /*组装电池数据*/
         assembleBatteryFormData(res){
             let obj = res.batteryOverview
            if (obj) {
                let data = {
                    batteryId:obj.batteryId+''== 'null'?'':obj.batteryId+'' ,
                    batteryNum:obj.batteryNum ,
                    masterBox:obj.masterBox,
                    masterBoxNum:obj.masterBoxNum ,
                    maxEpsPower:obj.maxEpsPower,
                    parallelBox:obj.parallelBox,
                    parallelBoxNum:obj.parallelBoxNum,
                    selfUseRate:obj.selfUseRate ,
                    suggestion:obj.suggestion ,
                    totalCapacity:obj.totalCapacity,
                }
                this.batteryFormData =data
            }
           
        },
        /*组装设备选择数据*/
         assembletEquipmentFormData(res){
             let obj = res.tEquipment
            if (obj) {
                let data = {
                    bmsBoxNum: obj.bmsBoxNum,
                    bmsBoxType:obj.bmsBoxType+''== 'null'?'':obj.bmsBoxType+'',
                    chargerId: obj.chargerId+''== 'null'?'':obj.chargerId+'',
                    chargerNum: obj.chargerNum,
                    datahubNum: obj.datahubNum,
                    datahubType: obj.datahubType+''== 'null'?'':obj.datahubType+'',
                    epsBoxNum: obj.epsBoxNum,
                    epsBoxType: obj.epsBoxType+''== 'null'?'':obj.epsBoxType+'',
                    fourGNum: obj.fourGNum,
                    fourGType: obj.fourGType+''== 'null'?'':obj.fourGType+'',
                    heatpumpId: obj.heatpumpId+''== 'null'?'':obj.heatpumpId+'',
                    heatpumpNum: obj.heatpumpNum,
                    lanNum: obj.lanNum,
                    lanType: obj.lanType+''== 'null'?'':obj.lanType+'',
                    mateBoxNum: obj.mateBoxNum,
                    mateBoxType: obj.mateBoxType+''== 'null'?'':obj.mateBoxType+'',
                    meterNum: obj.meterNum,
                    meterType: obj.meterType+''== 'null'?'':obj.meterType+'',
                    wifiNum: obj.wifiNum,
                    wifiType: obj.wifiType+''== 'null'?'':obj.wifiType+'',
                }
                this.equipmentFormData =data
            }
           
        },
        cs(){
            this.next = !this.next
        },
        changeClick(item){
            if(item.id == 2){
                // 加载光伏模块数据
            }else if(item.id == 3){
                // 加载逆变器数据
            }else if(item.id == 4){
                // 加载电缆配置数据
            }else if(item.id == 6){
                // 结果概述
            }
            // console.log(item,'item');
            this.next = false
        },
        changeNext(flag){
            this.next = flag
        },
        // 点击下一步
        nextMenus(value){
            // console.log(active,"active");
            this.active = value
        }

    },
};
</script>

<style  lang="less">
.addProjectList-main-box{
    min-width: 1260px;
    .steps-box{
        width: 80%;
        margin: 0 auto;
        
        .next{
            .iconfont{
                color: #FFAF40;
            }
            .el-step__title.is-wait{
                color: #FFAF40;
                border-color:#FFAF40;
            }
        }
        .el-steps--simple{
            background-color: #fff;
        }
    }
    .tabs-content-box{
         margin-top: 20px;
    }
    .el-card{
        overflow: inherit;
    }
    .el-card__header {
        padding: 8px 10px;
        background: #f5f5f5;
    }
    .el-card .box-card .is-always-shadow{
        transition: all .3s ease;
    }
    .el-card:hover {
        // transform: scale(1.015);
        // transform: translate3d(-10px,-5px,0px);
        // box-shadow: 10px 10px 20px 0px rgba(3, 81, 155, 0.3);
    }
}
</style>
